

import { useState } from 'react';
import config from '../config';
import { useGetContract } from './useGetContract';
import { MAX_VALUE, ContractNames } from '../constants';
import { useWeb3React } from '@web3-react/core';
import { toWei } from '../numbers';
import { callNotification } from '../notification';

export const useStakingApprove = () => {
  const { account } = useWeb3React();
  const { getContract } = useGetContract();
  const [isApproved, setIsApproved] = useState(false);
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);

  const checkApprove = async () => {
    const MIN_BALANCE = parseInt(MAX_VALUE, 16);
  
    try {
      const contractToken = await getContract(ContractNames.POLLY_TOKEN);

      const approveBalance = await contractToken.allowance(account, config.contractStaking);

      const isAllowed = approveBalance >= MIN_BALANCE;
  
      if (isAllowed) {
        setIsApproved(true);
      } else {
        setIsApproved(false);
      }
    } catch (e) {
        setIsApproved(false);
    }
  };

  const callApprove = async () => {  
    try {
        const contractToken = await getContract(ContractNames.POLLY_TOKEN);

        const result = await contractToken.approve(config.contractStaking, MAX_VALUE);
  
        setIsLoadingApprove(true);
  
        callNotification({ type: 'info', message: 'Transaction was sent. Please wait', autoClose: 10000 });
  
        await result.wait();
  
        setIsApproved(true);
  
        checkApprove();
    } catch (e) {
        setIsApproved(false);
    } finally {
      setIsLoadingApprove(false);
    }
  };


  return {
    isApproved,
    isLoadingApprove,
    checkApprove,
    callApprove
  };
};
  