import React from 'react';
import { Link } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import { shortenAddress } from '../../../../helpers/format';

export const Register = () => {
  const { account } = useWeb3React();
  return (
    <>
      <div className="flex flex-col justify-between z-[1] lg:justify-start lg:space-y-[4rem] lg:w-full">
        <div className="flex flex-col space-y-[0.4rem]">
          <span className="text-[4rem] text-white font-semibold leading-normal space-grotesk lg:text-[3rem]">
            Register in Polinetica
          </span>
          <span className="text-[1.6rem] text-white-500 leading-[2.24rem] lg:max-w-[30.8rem] lg:max-w-full">
            Wallet {shortenAddress(account)} is not registered in Forsage. <br className="lg:hidden" />
            Create an account to join.
          </span>
        </div>
        <div className="flex space-x-[2rem] lg:w-full">
          <Link className="lg:w-full" to="/register">
            <button className="flex items-center justify-center px-[1.6rem] bg-[#985AFF] rounded-[1.2rem] h-[4.8rem] lg:w-full ">
              <span className="text-[1.6rem] text-white  font-semibold leading-normal">Join</span>
            </button>
          </Link>
        </div>
      </div>
      <img
        className="absolute bottom-0 w-[30rem] z-[2] right-[12rem] lg:left-1/2 lg:-translate-x-1/2 z-[1]"
        src="/images/login/headerBanner/register/poly.webp"
      />
      <img
        className="absolute w-[12rem] right-[7rem] top-[-5rem] right-[33rem] z-[1] lg:right-0 lg:top-[30rem] lg:w-[13rem]"
        src="/images/login/headerBanner/register/coin-1.webp"
      />
      <img
        className="absolute right-[5rem] w-[7rem] bottom-[4rem] z-[1] lg:hidden"
        src="/images/login/headerBanner/register/coin-2.webp"
      />
      <img
        className="hidden lg:flex absolute w-[7rem] left-[1rem] bottom-[7rem] z-[1]"
        src="/images/login/headerBanner/register/coin-2mob.webp"
      />
      <img
        className="absolute w-[10.2rem] right-[31.8rem] z-[1] top-[3rem] lg:top-auto lg:bottom-[25.7rem] lg:w-[9.5rem] lg:left-0 "
        src="/images/login/headerBanner/register/coin-3.webp"
      />
    </>
  );
};
