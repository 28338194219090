import React, { useState } from 'react';
import Checkicon from '../../../../assets/icons/generalIcons/whiteCheckIcon.svg';
import { RegisterBar } from '../registerBar';

export const PlanCard = ({ name, type, price, color, textColor, onClick, chosePlan, id }) => {
  return (
    <div
      className={`flex rounded-[2rem] border-[0.1rem] border-solid border-white-100 bg-black-300 backdrop-blur-[5rem] overflow-hidden register-plan-animation lg:flex lg:flex-col ${
        chosePlan ? 'p-[4rem] mt-[15rem] lg:p-[2.8rem] lg:mt-0 lg:w-full' : 'p-[2.8rem] h-[45.9rem] w-full lg:h-full'
      }`}
    >
      <div
        className={`flex flex-col justify-between ${
          chosePlan ? 'w-[19rem]' : ' w-full'
        } lg:space-y-[3.2rem] z-[1] lg:w-full`}
      >
        <div className="flex flex-col space-y-[3.2rem] w-full">
          <div className={`flex flex-col space-y-[1.2rem] w-full ${chosePlan && 'lg:space-y-[0.4rem]'}`}>
            <div className="flex items-center justify-between w-full">
              <span className="text-[2.4rem] text-white font-600">{name}</span>
              <div
                className={`flex items-center justify-center bg-${color} rounded-[2rem] px-[1rem] h-[2.8rem] register-plan-animation ${
                  chosePlan && 'hidden'
                }`}
              >
                <span className={`text-[1.2rem] text-${textColor} font-500`}>{type}</span>
              </div>
            </div>
            <div className="flex flex-col">
              <span className="text-[1.8rem] text-white-500 font-500 leading-[2.88rem] lg:text-[1.6rem] lg:leading-[2.56rem]">
                Price a plan are <br className="lg:hidden" />
                <span className="text-white font-600">{price}$</span> /once
              </span>
            </div>
          </div>

          <div className={`flex flex-col space-y-[1.2rem] register-plan-animation ${chosePlan && 'hidden'}`}>
            <div className="flex items-center space-x-[1.6rem]">
              <img src={Checkicon} />
              <span className="text-[1.6rem] font-500 text-white leading-[2.24rem]">Activation of Duo</span>
            </div>
            <div className="flex items-center space-x-[1.6rem]">
              <img src={Checkicon} />
              <span className="text-[1.6rem] font-500 text-white leading-[2.24rem]">Level 1</span>
            </div>
            <div className="flex items-center space-x-[1.6rem]">
              <img src={Checkicon} />
              <span className="text-[1.6rem] font-500 text-white leading-[2.24rem]">Quantity XP 1000</span>
            </div>
          </div>
        </div>

        <button
          onClick={() => onClick(id)}
          className={`flex items-center justify-center h-[4.8rem] bg-${color} rounded-[1.2rem] custom-transition z-[1] hover:opacity-70 ${
            chosePlan &&
            'bg-white-50 lg:bg-white-100 lg:h-[2.8rem] lg:rounded-[2rem] lg:px-[1.2rem] lg:w-fit  lg:absolute lg:right-[2.8rem] lg:top-[0rem] '
          }`}
        >
          <span className={`text-[1.6rem] font-500 text-white leading-[2.24rem] ${chosePlan && 'lg:text-[1.4rem]'}`}>
            {chosePlan ? 'Change' : 'Subscribe'}
          </span>
        </button>
        {id === 2 && (
          <img
            className="hidden lg:flex absolute bottom-0 right-0 z-[0]"
            src="/images/register/Plans/RegisterBar/shadow.webp"
          />
        )}
      </div>
      {chosePlan && (
        <>
          <RegisterBar />
          <img className="absolute bottom-0 right-0 z-[0]" src="/images/register/Plans/RegisterBar/shadow.webp" />
        </>
      )}
    </div>
  );
};
