import React, { useMemo, useState } from 'react';
import { Modal } from '../../Modal';
import roadMapPink from '../../../assets/icons/roadMap/roadMapPinkIcon.svg';

export const RoadMapModal = ({ openedModal, handleCloseModal }) => {
  const roadMapItems = [
    {
      date: 'January 27',
      isActive: true,
    },
    {
      date: 'January 28',
      title: 'Unsold POLLY Tokens Burn',
      desc: "We'll burn all unsold POLLY tokens to increase the value of your holdings.",
      isActive: false,
    },
    {
      date: 'Within 7 days after DEX listing',
      title: 'Public Listings',
      desc: '$POLLY will be listed on DEXTools, DexViewer, DexScreener, CoinMarketCap, and CoinGecko.',
      isActive: false,
    },
    {
      date: 'February 3',
      title: 'Polynetica Social Mining Programs Launch',
      desc: 'You can earn rewards by participating in our social mining programs.',
      isActive: false,
    },
    {
      date: 'Q1 2025',
      title: 'First CEX Listing will be in',
      desc: 'You can earn rewards by participating in our social mining programs.',
      isActive: false,
    },
  ];
  return (
    <Modal className="!mb-[0rem]" isOpened={openedModal} onClose={handleCloseModal}>
      <div className="w-[85rem] h-[72rem] !relative flex flex-col items-start justify-start space-y-[4.8rem] px-[4rem] pt-[4rem] rounded-[3rem] border-[0.1rem] border-solid border-white-50 bg-white-50 bg-blend-difference shadow-[0px_20px_50px_0px_rgba(0,0,0,0.40)] backdrop-blur-[5rem] lg:w-full lg:px-[2rem] lg:rounded-none lg:rounded-t-[2rem] lg:space-y-[2.4rem] lg:h-[60rem] lg:border-none ">
        <div className="flex items-start space-x-[1.2rem]">
          <img className="w-[2.4rem] h-[2.4rem]" src={roadMapPink} />
          <div className="flex flex-col space-y-[1.2rem]">
            <span className="text-[3.2rem] text-white font-600 leading-[3.2rem] tracking-[-0.64px] lg:text-[2.4rem] lg:leading-[2.4rem] lg:tracking-[-0.48px]">
              Polynetica Roadmap
            </span>
            <span className="text-[1.4rem] font-500 leading-[1.96rem] text-white-500">
              Mark These Dates in Your Calendar
            </span>
          </div>
        </div>
        <div className="relative flex flex-col items-start justify-start h-full w-full lg:overflow-auto hide-scroll lg:flex-row">
          <div className="absolute left-[0.4rem] h-[98%] !bottom-0 w-[0.2rem] bg-white-100 z-[0] lg:fixed lg:left-[2.37rem] lg:h-[78%]" />
          <div className="flex flex-col space-y-[2.4rem] lg:space-y-[1.2rem] lg:h-full">
            {roadMapItems.map((item, itemIndex) => {
              const blockClass = `flex flex-col items-start justify-center space-y-[0.8rem] p-[1.6rem] rounded-[2rem] border-[0.1rem] border-solid border-white-100 lg:rounded-[1.2rem] ${
                item?.isActive ? 'bg-white-900' : ''
              }`;
              const titleClass = `text-[1.6rem] font-600 leading-[2.24rem] lg:text-[1.4rem] lg:font-500 lg:leading-[1.96rem] ${
                item?.isActive ? 'text-black' : 'text-white'
              }`;
              const descClass = `text-[1.3rem] font-500 leading-[1.82rem] lg:text-[1.2rem] lg:font-400 lg:leading-[1.68rem] ${
                item?.isActive ? 'text-black-500' : 'text-white-500 '
              }`;
              return (
                <div
                  className={`flex items-start space-x-[2.4rem] lg:flex-col lg:space-y-[1.2rem]  ${
                    itemIndex === roadMapItems.length - 1 && 'lg:pb-[2rem]'
                  }`}
                  key={itemIndex}
                >
                  <div className="flex items-center space-x-[2.4rem] z-[1]">
                    <div className="w-[1rem] h-[1rem] bg-[#AF7FFF] rounded-full shrink-0" />
                    <span className="text-[1.8rem] font-500 leading-[2.52rem] text-white w-[15rem] lg:w-full">
                      {item?.date}
                    </span>
                  </div>
                  {itemIndex === 0 ? (
                    <div className="flex space-x-[1.2rem] lg:flex-col lg:space-x-0 lg:space-y-[1.2rem]">
                      <div className={blockClass}>
                        <span className={titleClass}>DEX Launch</span>
                        <span className={descClass}>New $POLLY listings.</span>
                      </div>
                      <div className={blockClass}>
                        <span className={titleClass}>Polynetica Staking Launch</span>
                        <span className={descClass}>You can stake your $POLLY and start earning rewards!</span>
                      </div>
                    </div>
                  ) : (
                    <div className={blockClass}>
                      <span className={titleClass}>{item?.title}</span>
                      <span className={descClass}>{item?.desc}</span>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
};
