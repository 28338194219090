import React from 'react';
import { LeftBar, Header } from 'components';
import { MobHeader } from '../../components/MobHeader';

export const BaseLayout = ({ children, className }) => {
  return (
    <div
      className={`relative flex bg-[#0C0C0E] items-start justify-center min-h-screen min-w-full overflow-hidden lg:bg-[#020204] ${className} `}
    >
      <MobHeader />
      <div className="relative flex w-full z-[1] max-w-[134rem]">
        <LeftBar />
        <div className="flex flex-col w-full lg:pt-[6.4rem]">
          <Header />
          <div className="flex flex-col space-y-[2rem] w-full p-[2rem] lg:p-0">{children}</div>
        </div>
      </div>
      <img className="absolute left-0 lg:hidden" src="/images/layouts/base/shadows/leftShadow.webp" alt="" />
    </div>
  );
};
