import React from 'react';
import partnersIcon from '../../../../assets/icons/level/footer/partnersIcon.svg';
import cyclesIcon from '../../../../assets/icons/level/footer/cyclesIcon.svg';
import arrowIcon from '../../../../assets/icons/level/footer/arrowIcon.svg';
import polygonIcon from '../../../../assets/icons/header/polygonIcon.svg';

export const Footer = () => {
  const recycleSelect = (isMobileHidden) => {
    return (
      <div
        className={`${
          isMobileHidden ? 'flex lg:hidden' : 'hidden lg:flex'
        } flex items-center justify-center space-x-[1rem] bg-[#985AFF] rounded-[3rem] px-[1.6rem] h-[4.6rem] lg:w-full lg:justify-between lg:rounded-[4rem]`}
      >
        <div className="flex space-x-[0.1rem] text-[1.4rem] font-500">
          <span className="text-white-500">
            Cycle <span className="text-white">432</span>
          </span>
        </div>
        <img src={arrowIcon} />
      </div>
    );
  };
  return (
    <div className="flex w-full items-center justify-between lg:flex-col lg:space-y-[2rem]">
      <div className="flex w-full justify-between">
        <div className="flex space-x-[4rem] items-center ">
          <div className="flex flex-col space-y-[1.2rem]">
            <span className="text-[1.4rem] font- text-white-500 leading-none">Partners</span>
            <div className="flex items-center space-x-[0.4rem]">
              <img className="W-[2.4rem] h-[2.4rem]" src={partnersIcon} />
              <span className="text-[1.6rem] text-white font-600 leading-[2.24rem]">794</span>
            </div>
          </div>
          <div className="flex flex-col space-y-[1.2rem]">
            <span className="text-[1.4rem] font-medium text-white-500  leading-none">Cycles</span>
            <div className="flex items-center space-x-[0.4rem]">
              <img src={cyclesIcon} />
              <span className="text-[1.6rem] text-white font-600 leading-[2.24rem]">432</span>
            </div>
          </div>
          {recycleSelect(true)}
        </div>
        <div className="flex flex-col space-y-[1.2rem]">
          <span className="text-[1.4rem] text-white-500  leading-none">Total level revenue</span>
          <div className="flex items-center space-x-[0.4rem]">
            <span className="text-[1.6rem] text-white font-600 leading-[2.24rem]">17,500 POL</span>
            <img src={polygonIcon} />
          </div>
        </div>
      </div>
      {recycleSelect(false)}
    </div>
  );
};
