import React from 'react';
import partnerIcon from '../../../../assets/icons/level/levelMap/partnerIcon.svg';
import plusIcon from '../../../../assets/icons/level/levelMap/plusIcon.svg';

export const LevelMap = () => {
  return (
    <div className="flex items-center space-x-[4rem] lg:space-x-0 lg:justify-between lg:w-full z-[1]">
      <div className="flex flex-col items-center justify-between border-[0.1rem] border-white-200 p-[2rem] w-[14rem] h-[14rem] bg-[#985AFF] rounded-[2rem] backdrop-blur-[50px] shadow-[0px_40px_30px_-20px_rgba(0,0,0,0.25)] lg:w-[10rem] lg:h-[10rem] lg:rounded-[1.43rem] lg:p-[1.43rem]">
        <img className="w-[4.8rem] h-[4.8rem] lg:w-[3.4rem] lg:h-[3.4rem]" src={partnerIcon} />
        <div className="flex items-center justify-center bg-white-100 rounded-full h-[3rem] px-[1rem] lg:h-[2.1rem] lg:px-[0.7rem] ">
          <span className="text-[1.4rem] text-white font-400 lg:text-[1rem]">ID 124059</span>
        </div>
      </div>
      <div className="flex flex-col items-center justify-between border-[0.1rem] border-white-200 p-[2rem] w-[14rem] h-[14rem] bg-[#985AFF] rounded-[2rem] backdrop-blur-[50px] shadow-[0px_40px_30px_-20px_rgba(0,0,0,0.25)] lg:w-[10rem] lg:h-[10rem] lg:rounded-[1.43rem] lg:p-[1.43rem]">
        <img className="w-[4.8rem] h-[4.8rem] lg:w-[3.4rem] lg:h-[3.4rem]" src={partnerIcon} />
        <div className="flex items-center justify-center bg-white-100 rounded-full h-[3rem] px-[1rem] lg:h-[2.1rem] lg:px-[0.7rem]">
          <span className="text-[1.4rem] text-white font-400 lg:text-[1rem]">ID 124059</span>
        </div>
      </div>
      <div className="flex flex-col items-center justify-between border-[0.1rem] border-white-200 p-[2rem] w-[14rem] h-[14rem] bg-white-50 rounded-[2rem] backdrop-blur-[50px] shadow-[0px_40px_30px_-20px_rgba(0,0,0,0.25)] lg:w-[10rem] lg:h-[10rem] lg:rounded-[1.43rem] lg:p-[1.43rem]">
        <img className="w-[4.8rem] h-[4.8rem] lg:w-[3.4rem] lg:h-[3.4rem]" src={partnerIcon} />
        <div className="flex items-center justify-center space-x-[0.4rem] bg-white-100 rounded-full h-[3rem] px-[1rem] lg:h-[2.1rem] lg:px-[0.7rem] lg:space-x-[0.28rem]">
          <span className="text-[1.4rem] text-white font-400 lg:text-[1rem]">Invite </span>
          <img className="w-[0.71rem] h-[0.71rem]" src={plusIcon} />
        </div>
      </div>
    </div>
  );
};
