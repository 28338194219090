import React, { useEffect, useMemo, useState, useRef } from 'react';
import { RoadMap } from './RoadMap';
import { ActivateModal } from '../../../components/Modals/ActivateModal';
import { useModal } from '../../../helpers/hooks/useModal';
import { useWeb3React } from '@web3-react/core';
import { shortenAddress } from '../../../helpers/format';
import logoIcon from '../../../assets/icons/header/logo/logo.svg';
import purpleLogoIcon from '../../../assets/icons/header/logo/purpleLogo.svg';
import logoTextIcon from '../../../assets/icons/header/logo/logoText.svg';
import ExitIcon from '../../../assets/icons/main/exit.svg';
import MenuIcon from '../../../assets/icons/main/menu.svg';
import CloseIcon from '../../../assets/icons/main/closeWhite.svg';
import WalletIcon from '../../../assets/icons/main/wallet.svg';
import ArrowDownIcon from '../../../assets/icons/main/arrowDownMini.svg';
import WhaleIcon from '../../../assets/icons/whaleChat/whaleIcon.svg';
import arrowIcon from '../../../assets/icons/generalIcons/whiteArrowIcon.svg';
import roadMapIcon from '../../../assets/icons/roadMap/roadMapIcon.svg';

import { useOnClickOutside } from 'helpers/hooks/useOnClickOutside';

import { BurgerMenu } from './BurgerMenu';

import { Trans } from 'react-i18next';
import { MenuWidget } from './MenuWidget';
import { WalletWidget } from './WalletWidget';
import { Button } from '../../../components/Landing/Button';

import { useSelector } from 'react-redux';
import { getBalance } from '../../../store/walletSlice/selectors';
import { WhaleChatModal } from '../../../components/Modals/WhaleChatModal';
import { setCookie, parseCookies, destroyCookie } from 'nookies';
import config from '../../../helpers/config';
import { Link } from 'react-router-dom';
import { RoadMapModal } from '../../../components/Modals/RoadMapModal';

import { isBefore, fromUnixTime } from 'date-fns';
import { DEX_START_DATA } from '../../../helpers/tiers';

const OPENED_TYPE = {
  WALLET: 'wallet',
  MENU: 'menu',
};

export const Header = ({
  currentProjectStatus,
  onNavigate,
  withMenu = true,
  withSteps = true,
  className,
  purpleLogo,
}) => {
  const { account } = useWeb3React();
  const [isOpenedBurger, setIsOpenedBurger] = useState(false);

  // staking banner

  const [isOpenDex, setIsOpenDex] = useState(!isBefore(new Date(), fromUnixTime(DEX_START_DATA)));

  useEffect(() => {
    let interval;
    if (!document?.hidden) {
      interval = setInterval(() => {
        setIsOpenDex(!isBefore(new Date(), fromUnixTime(DEX_START_DATA)));
      }, 10000);
    }
    return () => clearInterval(interval);
  }, [document]);

  // staking banner end

  const [isOpenedWalletDesc, setIsOpenedWalletDesc] = useState(false);
  const [isOpenedMenuDesc, setIsOpenedMenuDesc] = useState(false);

  const [openedType, setOpenedType] = useState(OPENED_TYPE.MENU);
  const { openedModal, onOpen, onClose } = useModal();

  const {
    openedModal: openedWhaleChatModal,
    onOpen: onOpenWhaleChatModal,
    onClose: onCloseWhaleChatmodal,
  } = useModal();

  const { openedModal: openedRoadMapModal, onOpen: onOpenRoadMapModal, onClose: onCloseRoadMapmodal } = useModal();

  const { polly: balancePolly } = useSelector(getBalance);

  const refWallet = useRef();
  const refMenu = useRef();

  useOnClickOutside(refWallet, () => {
    setIsOpenedWalletDesc(false);
  });

  useOnClickOutside(refMenu, () => {
    setIsOpenedMenuDesc(false);
  });

  const connectWalletBtn = () => {
    return (
      <Button onClick={() => onOpen()} type="white" className="h-[4rem] !border-white">
        <Trans i18nKey="main.connectWallet"></Trans>
      </Button>
    );
  };

  const walletState = useMemo(() => {
    if (account) {
      return (
        <button
          onClick={() => openWalletDesc()}
          className="custom-transition py-[0.8rem] px-[1.2rem] flex items-center justify-center space-x-[1rem] bg-white-50 hover:bg-white-70 border border-white-100 rounded-[1.2rem]"
        >
          <div className="h-full flex items-center justify-center">
            {balancePolly > 0 && (
              // <div className="flex items-center space-x-[1rem] bg-[rgba(152,90,255,0.10)] rounded-[0.8rem] h-[2.8rem] px-[0.8rem]">
              //   <span className="text-[1.4rem] font-500 text-[#985AFF]">Add $POLLY</span>
              //   <div className="bg-[#985AFF] h-[0.6rem] w-[0.6rem] rounded-full"></div>
              // </div>
              <div className="bg-[#985AFF] h-[0.6rem] w-[0.6rem] rounded-full"></div>
            )}
          </div>
          <img className="w-[2.4rem] h-[2.4rem]" src={WalletIcon} alt="" />
          <div className="lg:hidden  border border-white-100 h-[2rem]"></div>
          <div className="flex items-center justify-center space-x-[1rem]">
            <span className="text-white-500 text-[1.6rem] font-500 leading-[2.4rem]">{shortenAddress(account)}</span>
            <img
              className={`custom-transition w-[1.6rem] [h-1.6rem] opacity-50 ${isOpenedWalletDesc ? 'rotate-180' : ''}`}
              src={ArrowDownIcon}
              alt=""
            />
          </div>
        </button>
      );
    } else {
      return connectWalletBtn();
    }
  }, [account, isOpenedWalletDesc, balancePolly]);

  const openWalletDesc = () => {
    setIsOpenedWalletDesc((value) => !value);
  };

  const toggleMenuDesc = () => {
    setIsOpenedMenuDesc((value) => !value);
  };

  const openWallet = () => {
    setOpenedType(OPENED_TYPE.WALLET);
    setIsOpenedBurger((value) => !value);
  };

  const toggleMenu = () => {
    setOpenedType(OPENED_TYPE.MENU);
    setIsOpenedBurger((value) => !value);
  };

  const cookies = parseCookies();

  useEffect(() => {
    setTimeout(() => {
      if (!cookies?.roadMapOpened) {
        onOpenRoadMapModal();
        setCookie(null, 'roadMapOpened', true, {
          maxAge: 30 * 24 * 60 * 60,
          path: '/',
        });
      }
    }, 1000);
  }, [cookies]);

  const menuSection = ({ isMobHidden = false }) => {
    return (
      <div
        className={`flex items-center justify-center space-x-[2rem] text-white ${
          isMobHidden ? 'flex lg:hidden' : 'hidden lg:flex'
        }`}
      >
        <button onClick={() => onOpenRoadMapModal()} className="flex items-center justify-center">
          <img className="w-[2.4rem] h-[2.4rem]" src={roadMapIcon} />
        </button>
        <button
          onClick={() => onOpenWhaleChatModal()}
          className="animate-pulse w-[4rem] h-[4rem] lg:w-[3rem] lg:h-[3rem] custom-transition hover:opacity-60"
        >
          <img src={WhaleIcon} />
        </button>
        <div className="flex lg:hidden" ref={refWallet}>
          {walletState}
          <WalletWidget isOpened={isOpenedWalletDesc} onClose={() => setIsOpenedWalletDesc(false)} />
        </div>

        {/* mobile wallet */}
        <div className="hidden lg:flex">
          {account ? (
            <div className="flex items-center justify-center space-x-[1rem]">
              <button
                onClick={() => openWallet()}
                className="w-[2.4rem] h-[2.4rem] items-center justify-center space-y-[0.4rem]"
              >
                <img src={WalletIcon} alt="" />
              </button>
            </div>
          ) : (
            connectWalletBtn()
          )}
        </div>

        {withMenu && (
          <>
            <div className="lg:hidden" ref={refMenu}>
              <button
                onClick={() => toggleMenuDesc()}
                className="w-[2.4rem] h-[2.4rem] flex items-center justify-center space-y-[0.4rem]"
              >
                <img src={isOpenedMenuDesc ? CloseIcon : MenuIcon} alt="" />
              </button>
              <MenuWidget
                onNavigate={onNavigate}
                isOpened={isOpenedMenuDesc}
                onClose={() => setIsOpenedMenuDesc(false)}
              />
            </div>

            <button
              onClick={() => toggleMenu()}
              className="hidden lg:flex w-[2.4rem] h-[2.4rem] flex items-center justify-center space-y-[0.4rem]"
            >
              <img src={isOpenedBurger ? CloseIcon : MenuIcon} alt="" />
            </button>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col z-[1111] fixed top-0 left-0 w-full lg:border-none lg:flex-col lg:items-start">
      <Link
        to="/staking"
        className={`flex items-center justify-center ${
          isOpenDex ? 'h-[4rem] lg:h-[5rem]' : '!h-0'
        } custom-transition bg-[#985AFF] w-full px-[6rem] lg:px-[2rem] lg:justify-end`}
      >
        <div className="absolute left-[6rem] lg:left-[2rem] flex items-center justify-center h-[2.4rem] bg-white px-[0.8rem] rounded-[0.8rem]">
          <span className="text-[1.4rem] text-[#985AFF] font-500 leading-[1.96rem]">New</span>
        </div>
        <div className="flex items-center space-x-[2.4rem] text-[1.6rem] font-600 text-white font-600 tracking-[-0.16px] lg:flex-col lg:items-end">
          <span>New Feature Polynetica Staking</span>
          <div className="flex items-center space-x-[0.4rem] custom-transition hover:opacity-80">
            <span>Stake now</span>
            <img src={arrowIcon} />
          </div>
        </div>
      </Link>
      <div
        className={`flex items-center justify-center w-full bg-[rgba(2,2,4,0.60)] ${className} border-b-solid border-b-[0.1rem] border-white-100 backdrop-blur-[5rem] h-[8rem] ${
          isOpenedBurger ? 'lg:h-screen' : 'lg:h-[11rem]'
        }`}
      >
        <div className="relative max-w-[140rem] flex items-center justify-between w-full h-full px-[2rem] lg:p-[2rem] lg:pb-0 lg:flex-col lg:items-start">
          <div className="flex items-center justify-between lg:w-full lg:h-[4.2rem]">
            <Link to="/">
              {purpleLogo ? (
                <img className="w-[15.3rem] m-[0.8rem]" src="/images/layouts/logo.webp" alt="" />
              ) : (
                <div className="flex space-x-[0.8rem] shrink-0">
                  <img className="hidden lg:block" src={logoIcon} />
                  <img className="lg:hidden" src={logoTextIcon} />
                </div>
              )}
            </Link>
            {menuSection({ isMobHidden: false })}
          </div>
          {withSteps && (
            <RoadMap
              currentProjectStatus={currentProjectStatus}
              className="hide-scroll py-[1.2rem] ml-[12rem] lg:ml-auto"
            />
          )}
          {menuSection({ isMobHidden: true })}
          {isOpenedBurger && (
            <BurgerMenu
              onClose={() => setIsOpenedBurger(false)}
              openedType={openedType}
              onNavigate={onNavigate}
              setIsOpenedBurger={setIsOpenedBurger}
              isOpenedBurger={isOpenedBurger}
            />
          )}
        </div>
        <ActivateModal openedModal={openedModal} handleCloseModal={onClose} />
        <WhaleChatModal
          scrollToSale={() => onNavigate('publicSale')}
          openConnectWallet={() => {
            onCloseWhaleChatmodal();
            onOpen();
          }}
          openedModal={openedWhaleChatModal}
          handleCloseModal={onCloseWhaleChatmodal}
        />
        <RoadMapModal openedModal={openedRoadMapModal} handleCloseModal={onCloseRoadMapmodal} />
      </div>
    </div>
  );
};
