import { useWeb3React } from '@web3-react/core';
import config from '../config';
import { Contract } from '@ethersproject/contracts';
import { ContractNames } from '../constants';

export const useGetContract = () => {
  const { account, provider } = useWeb3React();

  const types = {
    [ContractNames?.MATRIX_B]: [config.contractMatrixBase, config.contractMatrixBaseAbi],
    [ContractNames?.NFT_PASS]: [config.contractNftPass, config.contractNftPassAbi],
    [ContractNames?.NFT_PROMO]: [config.contractNftPromo, config.contractNftPromoAbi],
    [ContractNames?.TIERS_SALE]: [config.contractTiersSale, config.contractTiersSaleAbi],
    [ContractNames?.PANCAKE]: [config.contractPancake, config.contractPancakeAbi],
    [ContractNames?.POLLY_TOKEN]: [config.contractPollyToken, config.contractPollyTokenAbi],
    [ContractNames?.ST_POLLY_TOKEN]: [config.contractStPollyToken, config.contractStPollyTokenAbi],
    [ContractNames?.STAKING]: [config.contractStaking, config.contractStakingAbi],
  };

  const getContract = (type, otherProvider) => {
    return new Promise(function (resolve, rejected) {
      const currentProvider = otherProvider ? otherProvider : provider;

      if (types[type] && currentProvider) {
        const contract = new Contract(
          ...types[type],
          otherProvider ? otherProvider : provider?.getSigner(account).connectUnchecked() || provider,
        );

        resolve(contract);
      } else {
        rejected('error init contract: ' + type);
      }
    });
  };

  return {
    getContract,
  };
};
