import React from 'react';
import config from './helpers/config';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { PreviewPage } from './pages/PreviewPage';
import { Register } from './pages/Register';
import { Login } from './pages/Login';
import { Dashboard } from './pages/Dashboard';
import { Program } from './pages/Program';
import { Level } from './pages/Level';
import { Landing } from './pages/Landing';
import { StakingPage } from './pages/Staking';

export const RoutesFind = () => {
  const allStands = () => {
    return (
      <>
        <Route path="/" element={<Landing />} />
        <Route path="/entryPass" element={<PreviewPage />} />
        <Route path="/staking" element={<StakingPage />} />
      </>
    );
  };

  const devRoutes = () => {
    return (
      <Routes>
        <Route path="/register" element={<Register />} />
        <Route path="/w" element={<Login />} />
        <Route path="/" element={<Landing />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard/program" element={<Program />} />
        <Route path="/level" element={<Level />} />
        {allStands()}
      </Routes>
    );
  };

  const prodRoutes = () => {
    return <Routes>{allStands()}</Routes>;
  };

  return <Router>{config.stand === 'prod' ? prodRoutes() : devRoutes()}</Router>;
};
