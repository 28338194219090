import React, { useCallback } from 'react';
import Countdown from 'react-countdown';
import { fromUnixTime } from 'date-fns';

export const OpenSaleCardTimer = ({ time, onComplete, isCompleted }) => {
  const timerItem = (num, without = false) => {
    return (
      <>
        <div className={`h-full w-[3rem] flex items-center font-600 ${without ? 'justify-start' : 'justify-end'}`}>
          <span className="">{num}</span>
        </div>
        {!without && <div className="px-[0.4rem] animate-pulse font-600">:</div>}
      </>
    );
  };

  const renderer = useCallback(({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return null;
    } else {
      return (
        <div
          className={`flex flex-col justify-center items-center space-y-[0.25rem] px-[0.8rem] h-[5.5rem] rounded-[0.8rem] bg-[rgba(152,90,255,0.10)] text-[1.6rem] font-500 text-[#985AFF]`}
        >
          <div className="flex items-center justify-start">
            {days > 0 && timerItem(`0${days}d `)}
            {timerItem(`${hours < 10 ? '0' + hours : hours}h`)}
            {timerItem(`${minutes < 10 ? '0' + minutes : minutes}m`)}
            {timerItem(`${seconds < 10 ? '0' + seconds : seconds}s`, true)}
          </div>
          <span className="text-white">to Public Sale end</span>
        </div>
      );
    }
  }, []);

  return <Countdown renderer={renderer} autoStart date={fromUnixTime(time)} onComplete={onComplete} overtime />;
};
