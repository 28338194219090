import React from 'react';
import WhiteCopyIcon from '../../../../assets/icons/generalIcons/whiteCopyIcon.svg';
import GrayPlusIcon from '../../../../assets/icons/generalIcons/grayPlusIcon.svg';
import { copy } from '../../../../helpers/copy';

export const Reflink = ({ className }) => {
  const styleBg = {
    backgroundImage: `url('/images/register/bgPic.png')`,
    backgroundRepeat: 'round',
    backgroundSize: 'cover',
  };
  const refLink = 'polynetica.com/ref12446342';
  return (
    <div
      className={`flex flex-col justify-between p-[2rem] w-full dashboard-block-styles lg:space-y-[2.4rem]  ${className}`}
    >
      <div className="flex flex-col space-y-[0.8rem]">
        <span className="text-[1.6rem] font-500 leading-[2.2rem] text-white">Share the referral link</span>
        <span className="text-[1.2rem] leading-[1.68rem] text-white-500">You can also share your referral lin You</span>
      </div>
      <div className="relative flex items-center lg:hidden">
        <div className="absolute left-0 flex items-center justify-center border-[0.1rem] border-solid border-white rounded-full w-[3.2rem] h-[3.2rem] shadow-[-4px_4px_4px_0px_rgba(17,17,19,0.50)]">
          <div style={styleBg} className="w-full h-full bg-black rounded-full" />
        </div>
        <div className="absolute left-[2rem] flex items-center justify-center border-[0.1rem] border-solid border-white rounded-full w-[3.2rem] h-[3.2rem] shadow-[-4px_4px_4px_0px_rgba(17,17,19,0.50)]">
          <div style={styleBg} className="w-full h-full bg-black rounded-full" />
        </div>
        <div className="absolute left-[4rem] flex items-center justify-center border-[0.1rem] border-solid border-white rounded-full w-[3.2rem] h-[3.2rem] shadow-[-4px_4px_4px_0px_rgba(17,17,19,0.50)]">
          <div style={styleBg} className="w-full h-full bg-black rounded-full" />
        </div>
        <button className="absolute left-[6rem] flex items-center justify-center w-[3.2rem] h-[3.2rem] bg-white-100 border-[0.1rem] border-solid border-white-500 rounded-full shadow-[-4px_4px_4px_0px_rgba(17,17,19,0.50)] backdrop-blur-[0.5rem]">
          <img src={GrayPlusIcon} />
        </button>
      </div>
      <div className="h-[3.2rem] w-full">
        <div className="flex w-full space-x-[1.2rem] ">
          <input
            className="flex items-center text-[1.2rem] justify-center text-white-500 w-full h-[4rem] px-[1.6rem] rounded-[1rem] border-[0.1rem] border-solid border-white-100 border-[0.1rem] border-solid border-white-100 outline-none bg-transparent placeholder:text-white-500 "
            placeholder={refLink}
          />
          <button
            onClick={() => copy(refLink)}
            className="flex items-center justify-center h-[4rem] w-[4rem] bg-white-100 rounded-[1.2rem] shrink-0 lg:bg-[#AF7FFF]"
          >
            <img className="lg:w-[2rem] lg:h-[2rem]" src={WhiteCopyIcon} />
          </button>
        </div>
      </div>
    </div>
  );
};
