import React, { useMemo, useEffect, useState } from 'react';
import { bigIntToInt } from '../../../helpers/numbers';
import { getUnixTime } from 'date-fns';
import { AnimatedCounter } from '../../../components';

export const StakingListItem = ({
  amount,
  endDate,
  realAPR,
  startDate,
  onClickClaim,
  onClickUnstake,
  disabledAllUnstake,
  index,
}) => {
  const [isUnstake, setIsUnstake] = useState(false);
  const staked = bigIntToInt(amount);
  const apr = (bigIntToInt(realAPR) * 100).toFixed(1);
  const period = (Number(endDate) - Number(startDate)) / 86400;

  const [percentProgress, setPersentProgress] = useState(null);

  const lineProgressWidth = !!percentProgress ? percentProgress + '%' : '2%';

  useEffect(() => {
    if (endDate && startDate) {
      const calculate = ((getUnixTime(new Date()) - Number(startDate)) / (Number(endDate) - Number(startDate))) * 100;
      const currentTime = calculate > 100 ? 100 : calculate.toFixed(3);
      if (calculate > 100) {
        setIsUnstake(true);
      } else {
        setIsUnstake(false);
      }
      setPersentProgress(currentTime);
    }
  }, [startDate, endDate, index]);

  useEffect(() => {
    let interval;
    if (endDate && startDate) {
      interval = setInterval(() => {
        const calculate = ((getUnixTime(new Date()) - Number(startDate)) / (Number(endDate) - Number(startDate))) * 100;
        const currentTime = calculate > 100 ? 100 : calculate.toFixed(3);
        if (calculate > 100) {
          setIsUnstake(true);
        } else {
          setIsUnstake(false);
        }
        setPersentProgress(currentTime);
      }, 2500);
    }
    return () => clearInterval(interval);
  }, [startDate, endDate, index]);

  const claimBtn = useMemo(() => {
    if (isUnstake) {
      return (
        <button
          disabled={disabledAllUnstake}
          onClick={() => onClickUnstake()}
          className="h-[4rem] w-full flex items-center justify-center bg-white-70 rounded-[1.2rem] custom-transition disabled:opacity-40 hover:opacity-60"
        >
          <span className="text-white text-[1.6rem] font-500 leading-[140%]">Unstake</span>
        </button>
      );
    } else {
      return (
        <button
          onClick={() => onClickClaim()}
          className="flex items-center justify-center space-x-[0.4rem] h-[4rem] w-full bg-[#AF7FFF] rounded-[1.2rem] shadow-[0px_10px_40px_0px_rgba(175,127,255,0.30)] custom-transition disabled:opacity-40 hover:opacity-60"
        >
          <span className="text-white text-[1.6rem] font-500 leading-[140%]">Claim</span>
        </button>
      );
    }
  }, [percentProgress, isUnstake]);

  return (
    <div
      className="flex flex-col w-full min-h-[36.5rem] p-[4rem] lg:min-h-[26rem] lg:p-[2rem] rounded-[2rem] border border-solid border-white-50 bg-white-30"
      key={index}
    >
      <div className="w-full flex items-center justify-between">
        <span className="text-[2.4rem] font-600 text-white lg:text-[2rem]">{period} Days</span>
        <div
          className={`custom-transition flex items-center justify-center px-[1.2rem] py-[0.4rem] rounded-[2rem] bg-[rgba(81,246,137,0.10)] ${
            isUnstake ? '!bg-[rgba(175,127,255,0.10)]' : ''
          }`}
        >
          <span
            className={`custom-transition text-[#51F689] text-[1.4rem] leading-[1.96rem] ${
              isUnstake ? '!text-[#AF7FFF]' : ''
            }`}
          >
            {isUnstake ? 'Complete' : 'Activated'}
          </span>
        </div>
      </div>
      <div className="flex-1 w-full flex items-center justify-start space-x-[3.2rem]">
        <div className="flex flex-col items-start justify-start space-y-[1.2rem]">
          <span className="text-white-400 text-[1.4rem] font-400 leading-[1.6rem]">APY</span>
          <span className="text-[2.4rem] text-white font-600 leading-[2.8rem] lg:text-[2rem]">{apr}%</span>
        </div>
        <div className="flex flex-col items-start justify-start space-y-[1.2rem]">
          <span className="text-white-400 text-[1.4rem] font-400 leading-[1.6rem]">Staked</span>
          <span className="text-[2.4rem] text-white font-600 leading-[2.8rem] lg:text-[2rem]">
            {Number(staked).toFixed(3)} <span className="text-white-400">POLLY</span>
          </span>
        </div>
      </div>
      <div className="w-full flex flex-col items-start justify-start space-y-[1.2rem] mb-[2.4rem]">
        <div className="flex items-center justify-start space-x-[0.5rem]">
          <span className="text-white-400">Staking Period,</span>
          {!!percentProgress && (
            <>
              <AnimatedCounter endNum={percentProgress || 0} />
              <span>%</span>
            </>
          )}
        </div>
        <div className="h-[1.2rem] relative overflow-hidden bg-white-70 rounded-[0.8rem] w-full backdrop-blur-[2.5rem]">
          <div
            style={{ width: lineProgressWidth }}
            className="custom-transition absolute top-0 left-0 bg-[#51F689] h-full min-w-[2%] rounded-[0.8rem]"
          ></div>
        </div>
      </div>
      {claimBtn}
    </div>
  );
};
