import React from 'react';
import spiloverIcon from '../../assets/icons/level/marketingInfo/spiloverIcon.svg';
import cycleIcon from '../../assets/icons/level/marketingInfo/cycleIcon.svg';
import subtractIcon from '../../assets/icons/level/marketingInfo/subtractIcon.svg';

export const MarketingInfo = () => {
  const infoListFirst = [
    {
      title: 'Direct partner',
      content: <div className="bg-[#985AFF] w-[1.6rem] h-[1.6rem] rounded-[0.4rem]" />,
    },
    {
      title: 'Spillover from below',
      content: <img src={spiloverIcon} />,
    },
    {
      title: 'Spillover from above',
      content: <img className="rotate-180" src={spiloverIcon} />,
    },
    {
      title: 'Spillover from above',
      content: <img className="rotate-180" src={spiloverIcon} />,
    },
    {
      title: 'Marketing legend',
      content: <img src={cycleIcon} />,
    },
  ];

  return (
    <div className="flex justify-between space-x-[1.2rem] lg:space-x-0 ">
      <div className="flex space-x-[1.2rem] lg:space-x-0 lg:flex-wrap lg:gap-[1.2rem]">
        {infoListFirst.map((item, itemIndex) => {
          return (
            <div
              className="flex items-center space-x-[1.2rem] space-x-[1rem] rounded-full h-[3rem] pl-[1rem] pr-[1.2rem] bg-white-50 lg:w-max"
              key={itemIndex}
            >
              {item?.content}
              <span className="text-[1.4rem] text-white-500 ">{item?.title}</span>
            </div>
          );
        })}
        <div className="absolute right-[2rem] flex items-center justify-center space-x-[1rem] h-[3rem] pl-[0.7rem] pr-[1.2rem] bg-[#985AFF] rounded-full lg:relative lg:right-auto">
          <img src={subtractIcon} />
          <span className="text-[1.4rem] text-white  font-medium">Marketing legend</span>
        </div>
      </div>
    </div>
  );
};
