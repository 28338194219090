import React, { useEffect, useMemo } from 'react';
import OrangeCircle from '../../../../../assets/icons/landing/orangeCircle.svg';
import { ALL_TIERS_STATUS, TIERS } from '../../../../../helpers/tiers';
import { RefferalBlock } from '../../RefferalBlock';
import { TiersTimer } from '../../../../../components/Timer/TiersTimer';
import { TIERS_START_DATA } from '../../../../../helpers/tiers';

import { useSelector } from 'react-redux';
import { getTiersInfo } from '../../../../../store/tierSlice/selectors';
import YoutubeIcon from '../../../../../assets/icons/socials/youtubeIcon.svg';

import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useWeb3React } from '@web3-react/core';
import { useOpenSale } from '../../../../../helpers/hooks/useOpenSale';
import { JsonRpcProvider } from '@ethersproject/providers';
import { PUBLIC_URLS } from '../../../../../helpers/constants';
import config from '../../../../../helpers/config';

export const LeftBar = ({ isStartTimerEnd, setStartTimerEnd, uplineAddress }) => {
  const { currentTier, allStatus } = useSelector(getTiersInfo);
  const { provider } = useWeb3React();
  const { getPolyPricePerToken, pricePerToken } = useOpenSale();

  const currentProvider = provider ? provider : new JsonRpcProvider(PUBLIC_URLS?.[config.allowedChainId]);

  const progressLoader = useMemo(() => {
    const widthLine =
      allStatus === ALL_TIERS_STATUS.ENDED ? 100 : currentTier.index ? (currentTier.index / TIERS.length) * 100 : 10;
    return (
      <div className="progress progress-striped active relative overflow-hidden bg-white-50 rounded-[2rem] h-[3rem] w-full border-solid border-white-100 border-[0.1rem]">
        <div
          role="progressbar progress-striped"
          style={{ width: `${widthLine}%` }}
          className="progress-bar bg-[#FCB73E] rounded-[2rem] h-full"
        ></div>
      </div>
    );
  }, [currentTier, allStatus]);

  useEffect(() => {
    if (currentProvider && !document?.hidden) {
      getPolyPricePerToken();
    }
  }, [currentProvider, document]);

  useEffect(() => {
    let interval;
    if (currentProvider && !document?.hidden) {
      interval = setInterval(() => {
        getPolyPricePerToken();
      }, 10000);
    }
    return () => clearInterval(interval);
  }, [currentProvider, document]);

  return (
    <div className="flex flex-col space-y-[4rem] py-[3rem] px-[4rem] w-[57.3rem] rounded-[2rem] h-full shrink-0 bg-[linear-gradient(180deg,rgba(255,255,255,0.05)_0%,rgba(255,255,255,0.02)_100%)] lg:w-full lg:p-[2rem]">
      <div className="flex flex-col space-y-[4rem] w-full lg:space-y-[2rem]">
        <div className="w-full flex items-center justify-between space-x-[2rem] lg:space-x-0 lg:flex-col lg:items-start lg:space-y-[0.8rem]">
          <div className="flex items-center justify-center space-x-[1.2rem]">
            <span className="text-[4rem] font-600 text-white space-grotesk">$POLLY Tiers</span>
            <Link
              className="custom-transition hover:opacity-70"
              target="blank"
              to="https://www.youtube.com/@Polynetica"
            >
              <img src={YoutubeIcon} />
            </Link>
          </div>
          <TiersTimer time={TIERS_START_DATA} isCompleted={isStartTimerEnd} onComplete={() => setStartTimerEnd(true)} />
        </div>
        <div className="flex flex-col space-y-[2rem]">
          {progressLoader}
          <div className="flex items-center justify-between h-[4.8rem] bg-[#FCB73E] rounded-[1.2rem] pl-[0.8rem] pr-[2rem]">
            <div className="flex items-center space-x-[1.2rem]">
              <div className="flex items-center justify-center bg-[#020204] rounded-[0.8rem] h-[3.2rem] px-[1rem]">
                <span className="text-[1.4rem] font-600 text-white">DEX</span>
              </div>
              <span className="text-[#020204] text-[1.4rem] font-500 lg:text-[1.2rem]">DEX Listing Start Price</span>
            </div>
            <span className="text-[1.8rem] text-[#0C0C0E] font-600 lg:text-[1.6rem]">$0.000325</span>
          </div>
        </div>
        <span className="text-[1.4rem] leading-[1.96rem] font-400 text-white-500">
          <Trans i18nKey="landing.tiers.leftBar.desc">
            Each tier boasts a predefined token value, transaction token quantity, tier token allocation, and referral
            purchase incentives. Total tier supply is 12% of $POLLY emission (12 000 000 000 $POLLY).
          </Trans>
        </span>
      </div>
      <RefferalBlock type="tiers" isStartTimerEnd={isStartTimerEnd} uplineAddress={uplineAddress} />
    </div>
  );
};
