import React, { useEffect } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { RoutesFind } from './RoutesFind';
import { ManageProvider } from './layouts';
import { getOrCreateStore } from './store';
import { Web3ReactProvider } from '@web3-react/core';
import { metaMask, metaMaskHooks, walletConnectV2Hooks, walletConnectV2 } from './connectors/wallets';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import 'react-toastify/dist/ReactToastify.css';
import config from './helpers/config';
import { ToastContainer } from 'react-toastify';
import './style.scss';
import { InvalidNetworkModal } from './components/Modals/InvalidNetworkModal';
import { useTranslation } from "react-i18next";

const connectors = [
  [metaMask, metaMaskHooks],
  [walletConnectV2, walletConnectV2Hooks],
];

function App({ initialReduxState }) {
  const store = getOrCreateStore(initialReduxState);
  const { ready } = useTranslation();

  const client = new ApolloClient({
    uri: config.apiQraph,
    cache: new InMemoryCache(),
  });
  if (ready) {
    return (
      <Web3ReactProvider connectors={connectors}>
        <ReduxProvider store={store}>
          <ApolloProvider client={client}>
            <ManageProvider>
              <RoutesFind />
              <ToastContainer />
              <div className="elfsight-app-5c531740-a127-4425-9150-cc7e039938f3" data-elfsight-app-lazy></div>
            </ManageProvider>
          </ApolloProvider>
        </ReduxProvider>
      </Web3ReactProvider>
    );
  }

  return <span>loading</span>
  
}

export default App;
