import React from 'react';
import { BaseLayout } from '../../layouts';
import { LevelCard } from '../../features/level/LevelCard';
import { MarketingInfo } from '../../components';

export const Level = () => {
  return (
    <BaseLayout>
      <div className="flex flex-col space-y-[4.7rem] lg:space-y-0 lg:flex-col-reverse">
        <span className="text-[3rem] leading-[4rem] text-white space-grotesk font-semibold lg:leading-normal lg:pb-[0.2rem]">
          Level 3
        </span>
        <span className="text-[1.4rem] text-white-500 lg:leading-normal">Dashboard / Program Base / Level 3</span>
      </div>
      <div className="flex flex-col space-y-[4rem]">
        <LevelCard />
        <MarketingInfo />
      </div>
    </BaseLayout>
  );
};
