import React, { useState } from 'react';
import { LanguageSelector } from '../LanguageSelector';
import { SocialLinksComp } from '../SocialsListComp';
import { Link } from 'react-router-dom';
import { menuList } from 'helpers/menu';

export const LeftBar = () => {
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <div className="flex flex-col justify-between w-[30rem] h-[96.3rem] pl-[4rem] pb-[4rem] pr-[2rem] pt-[2rem] border-r-[0.1rem] border-[#161616] border-solid lg:hidden">
      <div className="flex flex-col space-y-[4rem]">
        <img className="w-[15.3rem] m-[0.8rem]" src="/images/layouts/logo.webp" alt="" />
        <div className="flex flex-col">
          {menuList.map((item, itemIndex) => {
            const isActive = currentTab === itemIndex;
            return (
              <Link
                onClick={() => setCurrentTab(itemIndex)}
                className={`flex items-center space-x-[1.6rem] py-[1rem] px-[1.2rem] rounded-[1.2rem] w-full custom-transition ${
                  isActive ? 'bg-white-80 text-white' : 'text-white opacity-50 '
                }`}
                key={itemIndex}
              >
                <img className="w-[2.4rem] h-[2.4rem]" src={isActive ? item?.activeIcon : item?.icon} />
                <span className="text-[1.6rem] font-500">{item?.title}</span>
              </Link>
            );
          })}
        </div>
      </div>
      <div className="pt-[4.8rem] flex flex-col space-y-[1rem] w-full">
        <LanguageSelector className="!w-full" />
        <SocialLinksComp className="justify-between w-full" />
      </div>
    </div>
  );
};
