import React from 'react';
import { ProgramCard } from './ProgramCard';

export const Programs = () => {
  return (
    <div className="flex flex-col space-y-[2.4rem] p-[2rem] rounded-[3rem] border-[0.1rem] border-solid border-white-50 bg-white-40 backdrop-blur-[1.5rem] lg:bg-transparent lg:border-none lg:p-0">
      <span className="text-[2.4rem] font-500 tracking-[-0.48px] text-white lg:px-[1.6rem] ">Polynetica Programs</span>
      <div className="grid grid-cols-2 gap-[2rem] lg:grid-cols-1 lg:w-full lg:flex lg:overflow-auto lg:w-full hide-scroll lg:gap-[1.2rem] lg:pl-[1.6rem] lg:pr-[1.6rem]">
        <ProgramCard type={'duo'} />
        <ProgramCard type={'quattro'} />
      </div>
    </div>
  );
};
