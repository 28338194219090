import React, { useMemo, useState } from 'react';
import LevelIcon from '../../../assets/icons/dashboard/programs/levelCard/levelIcon.svg';
import PolIcon from '../../../assets/icons/main/polCoin.svg';
import FrozenIcon from '../../../assets/icons/dashboard/programs/levelCard/frozenIcon.svg';

export const LevelCard = ({ lvl }) => {
  const [status, setStatus] = useState('');
  const [isActive, setIsActive] = useState(true);

  const levelState = useMemo(() => {
    switch (status) {
      case 'acitve':
        return <div>Добро пожаловать, гость!</div>;
      case 'frozen':
        return isActive ? <div>Пользователь активен!</div> : <div>Пользователь неактивен</div>;
      case 'inAcitive':
        return <div>Добро пожаловать, администратор!</div>;
      case 'missedPartner':
        return <div>Добро пожаловать, администратор!</div>;
      default:
        return <div>Неизвестный статус</div>;
    }
  }, [status]);

  const cardTitle = () => {
    return (
      <div className="flex items-center justify-between w-full ">
        <div className="flex items-center space-x-[0.4rem]">
          <div className="flex items-center justify-start space-x-[1.2rem] w-[11rem]">
            <div className="flex items-center justify-center w-[2.8rem] h-[2.8rem] rounded-[0.8rem] bg-white-50 border-[0.1rem] border-white-50 border-solid">
              <img src={LevelIcon} />
            </div>
            <span className="text-[1.6rem] text-white font-600 leading-[2.24rem]">Level {lvl}</span>
          </div>
          <div className="flex items-center justify-center bg-white-100 rounded-[2rem] pl-[0.8rem] pr-[0.5rem] h-[2.8rem] space-x-[0.8rem]">
            <span className="text-[1.4rem] font-500 tracking-[-0.28px] text-white opacity-50 leading-none">Price</span>
            <span className="text-white text-[1.4rem] font-500 tracking-[-0.28px] leading-none">20</span>
            <img className="w-[1.8rem] h-[1.8rem]" src={PolIcon} />
          </div>
        </div>
        <div
          className={`flex items-center space-x-[0.4rem] justify-center h-[2.8rem] px-[1.2rem]  rounded-[2rem] ${
            isActive ? 'bg-[rgba(81,246,137,0.10)] text-[#51F689]' : 'bg-[rgba(106,190,255,0.10)] text-[#6ABEFF] '
          }`}
        >
          <span className="text-[1.2rem] font-500 leading-[1.68rem]">{isActive ? 'Activated' : 'Frozen'}</span>
          {!isActive && <img src={FrozenIcon} />}
        </div>
      </div>
    );
  };

  const statics = useMemo(() => {
    return (
      <div className="flex flex-col space-y-[2.4rem]">
        <div className="flex space-x-[2.4rem]">
          <div className="flex flex-col space-y-[0.4rem] tracking-[-0.28px]">
            <span className="text-[1.2rem] text-white-500">Personal Partners</span>
            <span className="text-[1.4rem] text-white font-600">20</span>
          </div>
          <div className="flex flex-col space-y-[0.4rem] tracking-[-0.28px]">
            <span className="text-[1.2rem] text-white-500">Team Partners</span>
            <span className="text-[1.4rem] text-white font-600">150</span>
          </div>
        </div>
        <div className=" flex flex-col space-y-[0.5rem]">
          <span className="text-[1.2rem] text-white-500">Profit</span>
          <div className="flex items-center space-x-[0.8rem]">
            <span className="text-[2.4rem] text-white font-600 leading-none">1238.340</span>
            <img className="w-[2rem] h-[2rem]" src={PolIcon} />
          </div>
        </div>
      </div>
    );
  });

  const levelInfo = useMemo(() => {
    return (
      <>
        {isActive ? (
          <div className="flex space-x-[1rem] absolute right-0 bottom-[-0.4rem] ">
            <div className="flex">
              <img className="h-[11.52rem] lg:h-[8rem]" src="/images/dashboard/programs/levelCard/progress/empty.webp" />
              <img
                className="h-[11.52rem] absolute right-0 z-[111] -rotate-90 left-0 lg:h-[8rem]"
                src="/images/dashboard/programs/levelCard/progress/fill.webp"
              />
            </div>
            <div className="flex rotate-90">
              <img className="h-[11.52rem] lg:h-[8rem]" src="/images/dashboard/programs/levelCard/progress/empty.webp" />
              <img
                className="h-[11.52rem] absolute right-0 z-[111] -rotate-90 left-0 lg:h-[8rem]"
                src="/images/dashboard/programs/levelCard/progress/fill.webp"
              />
            </div>
          </div>
        ) : (
          <span></span>
        )}
      </>
    );
  });

  return (
    <div className="relative flex flex-col justify-between space-y-[4.8rem] w-full p-[2rem] border-[0.3rem] border-white-50 border-solid bg-white-40 w-full rounded-[2rem] overflow-hidden">
      {cardTitle()}
      {isActive && (
        <>
          <div className="flex">{statics}</div>
          {levelInfo}
          <img className="absolute bottom-0 lg:hidden" src="/images/dashboard/programs/levelCard/greenShadow.webp" />
        </>
      )}
    </div>
  );
};
