import React, { useCallback } from 'react';
import Countdown from 'react-countdown';
import { fromUnixTime } from 'date-fns';

export const OpenSaleTimer = ({ time, onComplete, isCompleted }) => {
  const timerItem = (num) => {
    return <span className="flex items-center justify-center w-[3.6rem] text-[#985AFF]">{num}</span>;
  };

  const renderer = useCallback(({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return null;
    } else {
      return (
        <div className="flex items-center justify-center text-[#985AFF] text-[2.4rem] font-500 leading-[160%]">
          {timerItem(`${days < 0 ? hours : hours + days * 24}`)}:
          {timerItem(`${minutes < 10 ? '0' + minutes : minutes}`)}:
          {timerItem(`${seconds < 10 ? '0' + seconds : seconds}`)}
        </div>
      );
    }
  }, []);

  return (
    <div className="max-w-[95%] h-[7.2rem] px-[4rem] lg:px-[2rem] absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 flex items-center justify-between w-full bg-[#1D1D1F] border border-solid border-[#985AFF] rounded-[2rem]">
      <div className="flex items-center justify-start space-x-[1.2rem]">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="Frame">
            <path
              id="Vector"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.0004 21.6004C14.5465 21.6004 16.9883 20.589 18.7886 18.7886C20.589 16.9883 21.6004 14.5465 21.6004 12.0004C21.6004 9.45431 20.589 7.01252 18.7886 5.21217C16.9883 3.41182 14.5465 2.40039 12.0004 2.40039C9.45431 2.40039 7.01252 3.41182 5.21217 5.21217C3.41182 7.01252 2.40039 9.45431 2.40039 12.0004C2.40039 14.5465 3.41182 16.9883 5.21217 18.7886C7.01252 20.589 9.45431 21.6004 12.0004 21.6004ZM12.9004 6.00039C12.9004 5.7617 12.8056 5.53278 12.6368 5.36399C12.468 5.19521 12.2391 5.10039 12.0004 5.10039C11.7617 5.10039 11.5328 5.19521 11.364 5.36399C11.1952 5.53278 11.1004 5.7617 11.1004 6.00039V12.0004C11.1004 12.4972 11.5036 12.9004 12.0004 12.9004H16.8004C17.0391 12.9004 17.268 12.8056 17.4368 12.6368C17.6056 12.468 17.7004 12.2391 17.7004 12.0004C17.7004 11.7617 17.6056 11.5328 17.4368 11.364C17.268 11.1952 17.0391 11.1004 16.8004 11.1004H12.9004V6.00039Z"
              fill="#985AFF"
            />
          </g>
        </svg>
        <span className="text-white text-[1.6rem] font-500 leading-[160%]">Sales opening in </span>
      </div>

      <Countdown renderer={renderer} autoStart date={fromUnixTime(time)} onComplete={onComplete} overtime />
    </div>
  );
};
