import React from 'react';
import { ActivateModal } from '../../../../components/Modals/ActivateModal';
import { useModal } from '../../../../helpers/hooks/useModal';

export const ConnectWallet = () => {
  const { openedModal, onOpen, onClose } = useModal();
  return (
    <>
      <div className="flex flex-col justify-between z-[1] lg:justify-start lg:space-y-[4rem] lg:w-full">
        <div className="flex flex-col space-y-[0.4rem]">
          <span className="text-[4rem] text-white font-semibold leading-normal space-grotesk lg:text-[3rem] whitespace-nowrap">
            Polynetica main page
          </span>
          <span className="text-[1.6rem] text-white-500 leading-[2.24rem] lg:max-w-[30.8rem lg:max-w-full">
            Connect wallet to Register or Login to your account
          </span>
        </div>
        <div className="flex space-x-[2rem] lg:w-full">
          <button
            onClick={onOpen}
            className="flex items-center justify-center px-[1.6rem] bg-[#985AFF] rounded-[1.2rem] h-[4.8rem] lg:w-full "
          >
            <span className="text-[1.6rem] text-white  font-semibold leading-normal">Connect Wallet</span>
          </button>
          <button className="flex items-center justify-center px-[1.6rem] bg-white-50 rounded-[1.2rem] h-[4.8rem] border-[0.1rem] border-white-50 lg:w-[9.3rem]">
            <span className="text-[1.6rem] text-white leading-normal font-semibold whitespace-nowrap">Help me</span>
          </button>
        </div>
      </div>
      <img
        className="absolute bottom-0 w-[30rem] z-[2] right-[9rem] lg:left-1/2 lg:-translate-x-1/2 z-[1]"
        src="/images/login/headerBanner/connectWallet/poly.webp"
      />
      <img
        className="absolute w-[8rem] right-[27rem] bottom-[3.5rem] right-[33rem] z-[1] lg:left-[-0.5rem] lg:bottom-[2.8rem] lg:w-[10rem]"
        src="/previewPage/coin-1.webp"
      />
      <img
        className="absolute right-[27rem] w-[5.1rem] top-[-3.5rem] z-[1] lg:top-auto lg:w-[7rem] lg:bottom-[23.2rem] lg:left-[7.2rem] lg:z-[0]"
        src="/previewPage/coin-2.webp"
      />
      <img
        className="absolute w-[9.2rem] right-[5.8rem] z-[1] top-[-4rem] lg:top-auto lg:bottom-[17.7rem] lg:right-[-1rem] lg:w-[10rem]"
        src="/previewPage/coin-3.webp"
      />
      <ActivateModal openedModal={openedModal} handleCloseModal={onClose} />
    </>
  );
};
