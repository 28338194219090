import React from 'react';

export const UserInfo = () => {
  const styleBg = {
    backgroundImage: `url('')`,
    backgroundRepeat: 'round',
    backgroundSize: 'cover',
  };

  const idBlock = ({ isMobHidden = false }) => {
    return (
      <div
        className={`flex items-center justify-center bg-[rgba(175,127,255,0.10)] rounded-[2rem] px-[1.2rem] h-[2.8rem] lg:h-[2.6rem] ${
          isMobHidden ? 'flex lg:hidden' : 'hidden lg:flex'
        }`}
      >
        <span className="text-[1.6rem] text-[#AF7FFF] font-500 leading-[3rem] lg:text-[1.4rem]">id940452</span>
      </div>
    );
  };

  return (
    <div className="relative flex flex-col items-center p-[4rem] rounded-[2rem] dashboard-block-styles shrink-0 lg:!rounded-none lg:!border-0 lg:bg-transparent lg:p-0 lg:pt-[4rem] lg:w-full">
      <div className="flex flex-col items-center space-y-[4.8rem] lg:w-full lg:space-y-[2.4rem]">
        <div className="flex items-center justify-center w-[15rem] h-[15rem] bg-white-200 z-[1] rounded-full lg:h-[9rem] lg:w-[9rem]">
          <div
            style={styleBg}
            className="w-[14rem] h-[14rem] bg-white-500 rounded-full z-[11] lg:w-[8rem] lg:h-[8rem]"
          ></div>
        </div>
        <div className="flex items-center flex-col space-y-[1.2rem] lg:space-y-[2rem]">
          <div className="flex flex-col items-center space-y-[2rem] lg:space-y-0">
            {idBlock({ isMobHidden: true })}
            <span className="text-[3.2rem] font-500 text-white leading-none lg:text-[2.4rem]">Ishad Rashad</span>
          </div>
          <div className="flex items-center space-x-[1.2rem]">
            {idBlock({ isMobHidden: false })}
            <span className="text-[1.2rem] text-white-500 leading-[2.2rem]">Joined 15/02/2021 </span>
          </div>
          <span className="text-[1.2rem] text-white-500 leading-[1.68rem] text-center max-w-[28.7rem] lg:hidden">
            You can also share your referral link to your friends or sharing it on social media.
          </span>
        </div>
      </div>
      <button className="absolute right-[2rem] top-[2rem] flex items-center justify-center px-[1.2rem] h-[2.6rem] bg-white-50 rounded-[2rem] lg:right-0 lg:top-[4rem]">
        <span className="text-[1.4rem] text-white-500 leading-[3rem]">Edit</span>
      </button>
    </div>
  );
};
