import React, { useState } from 'react';
import { BaseLayout } from '../../layouts';
import { LevelCard } from '../../features/programPage/LevelCard';
import { MarketingInfo } from '../../components';
import ArrowDownIcon from '../../assets/icons/main/arrowDownMini.svg';
import PolIcon from '../../assets/icons/main/polCoin.svg';
import ListIcon from '../../assets/icons/dashboard/listicon.svg';
import GridIcon from '../../assets/icons/dashboard/gridIcon.svg';

export const Program = () => {
  const maxLevel = 12;

  const displaySheet = [
    {
      icon: GridIcon,
    },
    {
      icon: ListIcon,
    },
  ];

  const [currenTub, setCurrentTub] = useState(0);

  return (
    <BaseLayout>
      <div className="flex flex-col w-full p-[4rem] relative rounded-[3rem] bg-[linear-gradient(0deg,rgba(255,255,255,0.04)_0%,rgba(255,255,255,0.04)_100%),#020204] border-[0.1rem] border-solid border-white-50 backdrop-blur-[5rem] lg:rounded-none lg:border-none lg:p-[1.6rem]">
        <div className="flex flex-col space-y-[3.2rem] w-full z-[11]">
          <div className="flex w-full justify-between space-y-0">
            <button className="flex items-center space-x-[1.2rem]">
              <div className="w-[0.4rem] h-[3.6rem] bg-[#985AFF] rounded-[2rem]" />
              <span className="text-[3rem] text-white font-600 leading-[4rem] tracking-[-0.6px] space-grotesk lg:text-[2.4rem] lg:tracking-[-0.48px] ">
                Program Duo
              </span>
              <img className="lg:w-[1.8rem]" src={ArrowDownIcon} alt="" />
            </button>
            <div className="flex items-center space-x-[2.4rem] lg:space-x-0">
              <div className="flex items-center space-x-[0.8rem]">
                <span className="text-white text-[2rem] font-600 tracking-[-0.4px]">1203 POL</span>
                <img src={PolIcon} />
              </div>
              <div className="flex items-center space-x-[1.2rem] lg:hidden">
                {displaySheet.map((item, itemIndex) => {
                  const isActive = currenTub === itemIndex;
                  return (
                    <button
                      onClick={() => setCurrentTub(itemIndex)}
                      className={`flex items-center justify-center w-[4rem] h-[4rem] border-[0.1rem] border-solid rounded-[1.2rem] custom-transition ${
                        isActive ? 'bg-white-100 border-white-300' : 'bg-white-50 border-white-50'
                      }`}
                      key={itemIndex}
                    >
                      <img className={`${!isActive && 'opacity-50'} custom-transition`} src={item?.icon} />
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="flex flex-col space-y-[2.4rem]">
            <div className="hidden lg:flex w-full justify-between items-center border-[0.1rem] border-dashed border-white-200 h-[7.6rem] rounded-[2rem] p-[2rem]">
              <div className="flex items-center space-x-[1rem]">
                <span className="text-[1.6rem] text-white font-600 leading-[2.24rem]">Level 5</span>
                <div className="flex items-center justify-center bg-white-100 rounded-[2rem] pl-[0.8rem] pr-[0.5rem] h-[2.8rem] space-x-[0.8rem]">
                  <span className="text-[1.4rem] font-500 tracking-[-0.28px] text-white opacity-50 leading-none">
                    Price
                  </span>
                  <span className="text-white text-[1.4rem] font-500 tracking-[-0.28px] leading-none">20</span>
                  <img className="w-[1.8rem] h-[1.8rem]" src={PolIcon} />
                </div>
              </div>
              <button className="flex items-center justify-center rounded-[3rem] bg-[#985AFF] shadow-[0px_0px_40px_0px_rgba(152,90,255,0.30)] px-[1.6rem] h-[3.6rem]">
                <span className="text-[1.4rem] font-500 text-white leading-[1.96rem]">Activate</span>
              </button>
            </div>
            {currenTub === 0 ? (
              <div className="grid grid-cols-2 gap-[2rem] w-full lg:gap-[1.2rem] lg:grid-cols-1">
                {new Array(maxLevel).fill({}).map((item, itemIndex) => {
                  const isActive = itemIndex < 4;
                  return <LevelCard isActive={isActive} lvl={itemIndex + 1} />;
                })}
              </div>
            ) : (
              <span className="text-white text-5xl">Будет список</span>
            )}
          </div>
        </div>
        <img className="absolute top-0 z-[0] lg:hidden" src="/images/dashboard/topShadow.webp" />
        <img
          className="absolute top-0 left-0 z-[0] h-[85rem] hidden lg:flex w-full"
          src="/images/dashboard/topShadowMob.webp"
        />
      </div>
    </BaseLayout>
  );
};
