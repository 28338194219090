import React, { useState } from 'react';
import { PlanCard } from './planCard';

export const Plans = () => {
  const [openPlan, setOpenPlan] = useState(null);

  const handleClick = (id) => {
    if (openPlan === id) {
      setOpenPlan(null);
    } else {
      setOpenPlan(id);
    }
  };
  return (
    <div className="flex flex-col space-y-[5.4rem] items-center justify-center w-full lg:space-y-[4.8rem]">
      {openPlan === null && (
        <div className="flex flex-col items-center justify-center space-y-[2.4rem] text-center register-plan-animation lg:space-y-[1.2rem]">
          <span className="text-[5.6rem] font-600 text-white tracking-[-1.12px] lg:text-[3.2rem] lg:tracking-[-0.64px]">
            Choose a plan
          </span>
          <span className="text-[1.8rem] text-white-500 font-500 leading-[2.88rem] max-w-[45.4rem] lg:text-[1.6rem] lg:leading-[2.56rem] lg:max-w-full lg:px-[5rem]">
            You can look up any registered account in the system or BUSD address to preview or click
          </span>
        </div>
      )}
      <div className="flex items-center justify-center space-x-[2rem] w-full lg:flex-col lg:space-x-0 lg:space-y-[1.2rem]">
        {openPlan === null || openPlan === 0 ? (
          <PlanCard
            name="Basic"
            type="Base"
            price="10"
            color="white-100"
            textColor="white-500"
            id={0}
            chosePlan={openPlan === 0}
            onClick={handleClick}
          />
        ) : null}
        {openPlan === null || openPlan === 1 ? (
          <PlanCard
            name="Premium"
            type="Middle"
            price="40"
            color="white-100"
            textColor="white-500"
            id={1}
            chosePlan={openPlan === 1}
            onClick={handleClick}
          />
        ) : null}
        {openPlan === null || openPlan === 2 ? (
          <PlanCard
            name="Elite"
            type="Popular"
            price="80"
            color="[#985AFF]"
            textColor="white"
            id={2}
            chosePlan={openPlan === 2}
            onClick={handleClick}
          />
        ) : null}
      </div>
    </div>
  );
};
