import React from 'react';
import { Link } from 'react-router-dom';
import polygonIcon from '../../../../assets/icons/header/polygonIcon.svg';
import PolIcon from '../../../../assets/icons/main/polCoin.svg';
import DuoIcon from '../../../../assets/icons/dashboard/programs/programCard/duoIcon.svg';
import QuattroIcon from '../../../../assets/icons/dashboard/programs/programCard/quattroIcon.svg';

export const ProgramCard = ({ type, title, value, maxActiveLevel = 3 }) => {
  const maxLevel = 12;

  const bgImage = {
    card1: '/images/dashboard/programCard/rightShadow.webp',
    card2: '/images/dashboard/programCard/leftShadow.webp',
  };

  return (
    <Link
      to="/dashboard/program"
      className="relative flex flex-col space-y-[4.8rem] items-center dashboard-block-styles p-[2rem] lg:w-[89%] lg:shrink-0 lg:space-y-[5.6rem]"
    >
      <div className="flex justify-between w-full ">
        <div className="flex items-center space-x-[1.2rem]">
          <img className="w-[2.8rem] h-[2.8rem]" src={type === 'duo' ? DuoIcon : QuattroIcon} />
          <span className="text-[1.8rem] font-600 text-white lg:text-[1.6rem]">Program Duo</span>
        </div>
        <div className="flex items-center space-x-[0.8rem]">
          <span className="text-[2.4rem] text-white font-600 leading-[5.76px] lg:text-[2rem]">1238.340</span>
          <img className="w-[2rem] h-[2rem]" src={PolIcon} />
        </div>
      </div>
      <div className="flex flex-col space-y-[2.4rem] w-full z-[11]">
        <div className="flex flex-col space-y-[1.6rem] w-full">
          <div className="flex items-center justify-between w-full lg:flex-col-reverse lg:items-start lg:space-y-reverse lg:space-y-[2.4rem]">
            <div className="flex items-center space-x-[1.2rem] ">
              <span className="text-[3.2rem] text-white font-600 leading-[7.68px]">32%</span>
              <span className="text-[1.2rem] text-white-500 tracking-[-0.24px] text-left">
                Total
                <br /> activity
              </span>
            </div>
            <div className="grid grid-cols-6 grid-rows-2 gap-[1rem] items-center justify-center">
              {new Array(maxLevel).fill({}).map((item, itemIndex) => {
                const isActive = itemIndex < maxActiveLevel;
                const isFirstAfterActive = itemIndex === maxActiveLevel;
                return (
                  <div className="flex justify-center items-center">
                    <div
                      //сделал бы объектом
                      key={itemIndex}
                      className={`rounded-full ${
                        isActive
                          ? `w-[1.8rem] h-[1.8rem] ${type === 'duo' ? 'bg-[#51F689]' : 'bg-[#F1A13B]'}`
                          : isFirstAfterActive
                          ? `w-[1.4rem] h-[1.4rem] ${
                              type === 'duo' ? 'bg-[rgba(81,246,137,0.30)]' : 'bg-[rgba(241,161,59,0.30)]'
                            }`
                          : 'bg-white-100 w-[1.2rem] h-[1.2rem]'
                      }`}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex space-x-[0.4rem] w-full">
            <div
              className={`h-[0.8rem] ${
                type === 'duo' ? 'bg-[#51F689]' : 'bg-[#F1A13B]'
              } bg-[#51F689] w-[40%] rounded-[2rem]`}
            />
            <div className="h-[0.8rem] bg-white-100 w-full rounded-[2rem]" />
          </div>
        </div>
        <div className="flex items-center space-x-[1.2rem] w-full">
          <button className="flex items-center justify-center h-[4rem] w-full bg-[rgba(12,12,14,0.50)] rounded-[1rem]">
            <span className="text-white text-[1.4rem] font-500 leading-[1.96rem]">Open</span>
          </button>
          <button
            className={`flex items-center justify-center h-[4rem] w-full rounded-[1rem] ${
              type === 'duo' ? 'bg-[#51F689]' : 'bg-[#F1A13B]'
            }`}
          >
            <span className="text-black text-[1.4rem] font-500 leading-[1.96rem]">Upgrade</span>
          </button>
        </div>
      </div>
      <img
        className="absolute bottom-0 z-[0]"
        src={`/images/dashboard/programs/programCard/${type === 'duo' ? 'duoShadow' : 'quattroShadow'}.webp`}
      />
    </Link>
  );
};
