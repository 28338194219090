import React, { useEffect, useState, useMemo } from 'react';
import { PROJECT_STATUS } from '../../../../helpers/projectProgress';
import { TiersTimer } from '../../../../components/Timer/TiersTimer';
import { TIERS_START_DATA, ALL_TIERS_STATUS } from '../../../../helpers/tiers';
import { useSelector } from 'react-redux';
import { getTiersInfo } from '../../../../store/tierSlice/selectors';
import { useTranslation, Trans } from 'react-i18next';

export const StepsList = ({ currentProjectStatus, setCurrentProjectStatus }) => {
  const [isStartTimerEnd, setStartTimerEnd] = useState(false);
  const { allStatus } = useSelector(getTiersInfo);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (allStatus === ALL_TIERS_STATUS.ENDED) {
      setCurrentProjectStatus(PROJECT_STATUS.PUBLIC_SALE);
    }
  }, [allStatus]);

  const tiersList = useMemo(() => {
    return [
      {
        title: '$POLLY Tiers',
        border: 'border-tiers-orange',
        borderHover: 'hover:border-tiers-orange',
        shadow: 'shadow-[0px_0px_40px_0px_rgba(252,183,62,0.30)_inset]',
        desc: () => {
          return (
            <Trans i18nKey="landing.stepList.desc.step1">
              Tokens are sold in tiers with predetermined prices and limited slots per tier. Each tier unlocks after the
              previous one is fully purchased.
            </Trans>
          );
        },
        img: (
          <img
            className="absolute bottom-[2rem] w-[28.23rem] left-1/2 -translate-x-1/2"
            src={`/images/landing/tiersList/tiersPoly.webp`}
          />
        ),
        isActive: allStatus === ALL_TIERS_STATUS.SALE,
        key: PROJECT_STATUS.TIERS,
        disable: false,
      },
      {
        title: '$POLLY Public Sale',
        border: 'border-purple-matrix',
        borderHover: 'hover:border-purple-matrix',
        shadow: 'shadow-[0px_0px_40px_0px_rgba(172,123,255,0.40)_inset]',
        desc: () => {
          return (
            <Trans i18nKey="landing.stepList.desc.step2">
              Tokens are sold at a rising rate, determined by the initial pool size and subsequent purchases. All
              purchases are handled by smart contracts.
            </Trans>
          );
        },
        img: (
          <img
            className="absolute bottom-[1rem] h-[18rem] left-1/2 -translate-x-1/2 lg:h-[16rem]"
            src={`/images/landing/tiersList/publicSale.webp`}
          />
        ),
        isActive: allStatus === ALL_TIERS_STATUS.ENDED,
        key: PROJECT_STATUS.PUBLIC_SALE,
        // disable: allStatus === ALL_TIERS_STATUS.SALE || allStatus === ALL_TIERS_STATUS.SOON,
        disable: false,
      },
      {
        title: 'Social Mining Programs',
        border: 'border-purple-matrix',
        borderHover: 'hover:border-purple-matrix',
        shadow: 'shadow-[0px_0px_40px_0px_rgba(172,123,255,0.40)_inset]',
        desc: () => {
          return (
            <Trans i18nKey="landing.stepList.desc.step3">
              Polynetica offers 2 social mining programs (SMP), DUO and QUATTRO. Each has 12 levels and an independent
              partner structure.
            </Trans>
          );
        },
        img: (
          <img className="absolute bottom-0 left-1/2 -translate-x-1/2" src={`/images/landing/tiersList/matrix.webp`} />
        ),
        isActive: currentProjectStatus === PROJECT_STATUS.PUBLIC_SALE,
        key: PROJECT_STATUS.MATRIX,
        disable: true,
      },
    ];
  }, [currentProjectStatus, i18n.language]);

  const handleScrollToDiv = (key) => {
    setCurrentProjectStatus(key);
    const element = document.getElementById(key);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth', // Плавная прокрутка
        block: 'nearest', // Остановка вертикальной прокрутки
        inline: 'center', // Центрирование по оси X
      });
    }
  };

  return (
    <div className="hide-scroll tiersListBg flex items-center  justify-between h-[38rem] w-full space-x-[1rem] p-[2rem] rounded-[3rem] border-dashed border-[0.1rem] border-white-70 lg:overflow-x-auto lg:overflow-y-hidden lg:bg-transparent lg:border-none">
      {tiersList.map((item, itemIndex) => {
        return (
          <button
            className={`cursor-pointer flex flex-col relative w-full h-full text-left items-start justify-between pt-[4rem] px-[4rem] bg-white-30 rounded-[2rem] custom-transition lg:pt-[2rem] lg:px-[2rem] lg:min-w-[30rem] ${
              item.key === currentProjectStatus
                ? `${item?.border} ${item?.shadow} border-[0.4rem] !bg-white-10`
                : `border-white-100 border-[0.1rem]`
            } ${item?.disable && 'opacity-30'} ${!item?.disable && `${item.borderHover}`}`}
            key={itemIndex}
            onClick={() => handleScrollToDiv(item.key)}
            disabled={item.disable}
            id={item.key}
          >
            <div className="flex flex-col space-y-[1.2rem] z-[1]">
              <div className="flex flex-wrap items-center justify-between w-full lg:flex-col lg:items-start lg:space-y-[0.8rem]">
                <span className="text-[2.4rem] font-600 text-white space-grotesk">{item?.title}</span>
                {item.key === PROJECT_STATUS.TIERS && (
                  <TiersTimer
                    time={TIERS_START_DATA}
                    isCompleted={isStartTimerEnd}
                    onComplete={() => setStartTimerEnd(true)}
                  />
                )}
                {item.key === PROJECT_STATUS.PUBLIC_SALE && allStatus === ALL_TIERS_STATUS.SALE && (
                  <div className="bg-white-100 rounded-[0.8rem] flex items-center justify-center py-[0.4rem] px-[0.8rem]">
                    <span className="text-white-500 text-[1.4rem] font-500">{t('landing.stepList.afterTiers')}</span>
                  </div>
                )}
              </div>
              <span className="text-[1.4rem] font-400 text-white-500 leading-[1.96rem] max-w-[32.6rem]">
                {item?.desc()}
              </span>
            </div>
            {item?.img}
          </button>
        );
      })}
    </div>
  );
};
