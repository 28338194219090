import React from 'react';
import { useWeb3React } from '@web3-react/core';
import { Link } from 'react-router-dom';
import { shortenAddress } from '../../../../helpers/format';

export const Login = () => {
  const { account } = useWeb3React();
  return (
    <>
      <div className="flex flex-col justify-between z-[1] lg:justify-start lg:space-y-[4rem]">
        <div className="flex flex-col space-y-[0.4rem]">
          <span className="text-[4rem] text-white font-semibold leading-normal space-grotesk lg:text-[3rem]">
            Nickname
          </span>
          <span className="text-[1.6rem] text-white-500 leading-[2.24rem] lg:max-w-[30.8rem]">
            Wallet {shortenAddress(account)} is registered
          </span>
        </div>
        <div className="flex space-x-[2rem] lg:w-full">
          <Link to="/dashboard">
            <button className="flex items-center justify-center px-[1.6rem] bg-[#985AFF] rounded-[1.2rem] h-[4.8rem] lg:w-[20.5rem] ">
              <span className="text-[1.6rem] text-white  font-semibold leading-normal">Login to your account</span>
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};
