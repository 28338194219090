import React from 'react';

export const MiniPrograms = ({ className }) => {
  const programsList = [
    {
      title: 'Duo',
      img: 'duo',
      shadow: 'douShadow',
    },
    {
      title: 'Quattro',
      img: 'quattro',
      shadow: 'quattroShadow',
    },
  ];

  return (
    <div className="flex flex-col justify-between p-[2rem] w-full h-[20rem] dashboard-block-styles lg:bg-transparent lg:border-transparent lg:p-0 lg:h-full">
      <span className="text-[1.6rem] font-500 leading-[2.2rem] text-white lg:hidden">Polynetica Programs</span>
      <div className="flex flex-col space-y-[1.2rem] lg:flex-row lg:space-y-0 lg:space-x-[1.2rem]">
        {programsList.map((item, itemIndex) => {
          return (
            <a
              className="relative flex items-center justify-between w-full rounded-[1rem] h-[4.8rem] px-[1.6rem] backdrop-blur-[1.8rem] bg-white-50 border-[0.1rem] border-solid border-white-50 overflow-hidden"
              key={itemIndex}
            >
              <span className="text-[1.6rem] text-white font-500 leading-[2.24rem]">{item?.title}</span>
              <img
                className="w-[4.72rem] h-[2.26rem]"
                src={`/images/dashboard/profile/miniPrograms/${item?.img}.webp`}
              />
              <img className="absolute right-0" src={`/images/dashboard/profile/miniPrograms/${item?.shadow}.webp`} />
            </a>
          );
        })}
      </div>
    </div>
  );
};
