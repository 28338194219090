import React from 'react';
import { Input } from '../../../components';

export const PreviewAccount = () => {
  return (
    <div
      className="preview-account-bg relative flex flex-col justify-between overflow-hidden rounded-[3rem] h-[26rem] w-full bg-white-50 border-[0.1rem]
        border-white-50 p-[4rem] lg:h-[54.8rem] lg:max-w-full backdrop-blur-[5rem] bg-blend-difference lg:justify-end lg:px-[2rem]"
    >
      <div className="flex flex-col space-y-[4.8rem] ">
        <div className="flex flex-col space-y-[1.2rem]">
          <span className="text-white text-[3rem] space-grotesk font-bold leading-none">Preview account</span>
          <span className="text-[1.4rem] leading-[1.96rem] text-white-500 max-w-[48.5rem]">
            You can look up any registered account in the system. Enter ID or BUSD address to preview or click Demo to
            view random account.
          </span>
        </div>
        <div className="flex space-x-[1rem] w-full">
          <Input placeholder="Enter ID" className="max-w-[40rem]" />
          <button className="flex items-center justify-center px-[1.6rem] h-[4.8rem] bg-[#985AFF] rounded-[1.2rem]">
            <span className="text-[1.6rem] leading-normal text-white  font-medium">Preview</span>
          </button>
        </div>
      </div>
    </div>
  );
};
