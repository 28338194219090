import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_USER_DATA } from '../graphRequests';

export const useGetUserInfo = () => {
  const [userInfo, setUserInfo] = useState({
    user: {},
    isLoading: true,
    isDone: false,
  });


  const [callRequest, props] = useLazyQuery(GET_USER_DATA, {
    variables: { user: null },
    fetchPolicy: 'cache-and-network',
  });

  const { loading, data, called } = props ?? {};

  useEffect(() => {
    setUserInfo({...userInfo, isLoading: loading})
  }, [loading])

  useEffect(() => {
    setUserInfo({...userInfo, isDone: called})
  }, [called])

  const getUserInfo = async (userAddress = '') => {
    try {
        await callRequest({ variables: { user: userAddress.toLowerCase() } }).then((result) => {
          if (!!result?.data?.user?.id) {
            const { id, refNumber, referral, internalId, totalPartners, tokenToReceive } = result?.data?.user;
            setUserInfo({ ...userInfo, user: { id, internalId, refNumber, referral, totalPartners, tokenToReceive }});
          } else {
            setUserInfo({ ...userInfo, user: null});
          }
        });
      } catch (e) {
        console.log(e);
      }
  }

  return {
    userInfo,
    getUserInfo,
  };
};
