import React, { useState, useEffect } from 'react';
import { Title } from '../../../components/Landing/Title';
import ProgressIcon from '../../../assets/icons/landing/journey/phase1/progressIcon.svg';
import CubeIcon from '../../../assets/icons/landing/journey/phase1/cubeIcon.svg';
import DresserIcon from '../../../assets/icons/landing/journey/phase1/dresserIcon.svg';
import ActivationIcon from '../../../assets/icons/landing/programs/activationIcon.svg';
import LinkIcon from '../../../assets/icons/landing/journey/phase2/linkIcon.svg';
import PidIcon from '../../../assets/icons/landing/journey/phase2/pidIcon.svg';
import SoldIcon from '../../../assets/icons/landing/journey/phase2/soldIcon.svg';
import BlockIcon from '../../../assets/icons/landing/journey/phase3/blockIcon.svg';
import ExchangeIcon from '../../../assets/icons/landing/journey/phase3/exchangeIcon.svg';
import MarketingIcon from '../../../assets/icons/landing/journey/phase3/marketingIcon.svg';

import { Trans } from 'react-i18next';
import { Slider } from '../../../components/Landing/Slider';

import { useSelector } from 'react-redux';
import { getTiersInfo } from '../../../store/tierSlice/selectors';

import { ALL_TIERS_STATUS } from '../../../helpers/tiers';
import { PROJECT_STATUS } from '../../../helpers/projectProgress';

export const Journey = () => {
  const { allStatus } = useSelector(getTiersInfo);
  const [currentProjectStatus, setCurrentProjectStatus] = useState(PROJECT_STATUS.TIERS);

  useEffect(() => {
    if (allStatus === ALL_TIERS_STATUS.ENDED) {
      setCurrentProjectStatus(PROJECT_STATUS.PUBLIC_SALE);
    }
  }, [allStatus]);

  const slides = [
    {
      content: (
        <>
          <div className="flex flex-col space-y-[1.2rem] ">
            <span className="text-[1.6rem] text-[#985AFF] font-600 space-grotesk lg:text-[1.4rem]">
              <Trans i18nKey="landing.journey.title">Polynetica's journey</Trans>
            </span>
            <div className="flex items-center space-x-[1.2rem] lg:justify-between lg:items-start">
              <span className="text-[4rem] font-600 leading-[4.4rem] space-grotesk text-white-500 lg:text-[2.4rem] lg:leading-[2.64rem] ">
                <span className="text-white">
                  <Trans i18nKey="landing.journey.phase.title">Phase</Trans> I
                </span>{' '}
                <Trans i18nKey="landing.journey.phase1.tieredSale">Tiered Sale</Trans>
              </span>
              {currentProjectStatus === PROJECT_STATUS.TIERS && (
                <div className="flex items-center space-x-[1rem] bg-[rgba(206,250,47,0.10)] rounded-[0.8rem] px-[0.8rem] h-[3rem] lg:shrink-0">
                  <img src={ProgressIcon} />
                  <span className="text-[1.6rem] text-[#CEFA2F] font-500">
                    <Trans i18nKey="landing.journey.phase.status.inProgress">In progress</Trans>
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="flex space-x-[1.2rem] w-full lg:flex-col lg:space-x-0 lg:space-y-[1.2rem] w-full">
            <div className="flex items-center space-x-[2rem] rounded-[2rem] w-[36.5rem] h-[10.6rem] p-[2rem] journeyList-bg z-[1] lg:w-full">
              <div className="flex flex-col p-[1.3rem] w-[5.8rem] h-[5.8rem] bg-[#985AFF] rounded-[1.6rem] border-solid border-[0.1rem] border-white-300 shadow-[0px_14px_10px_0px_rgba(0,0,0,0.10)] shrink-0">
                <img src={DresserIcon} />
              </div>
              <span className="text-white-400 text-[1.6rem] font-500 leading-[2.24rem] max-w-[24rem]">
                <Trans
                  i18nKey="landing.journey.phase1.features.desc1"
                  defaults="<white>A tiered token sale with predetermined</white> prices and limited slots per tier."
                  components={{ white: <span className="text-white" /> }}
                />
              </span>
            </div>
            <div className="flex items-center space-x-[2rem] rounded-[2rem] w-[36.5rem] h-[10.6rem] p-[2rem] journeyList-bg z-[1] lg:w-full">
              <div className="flex flex-col p-[1.3rem] w-[5.8rem] h-[5.8rem] bg-[#985AFF] rounded-[1.6rem] border-solid border-[0.1rem] border-white-300 shadow-[0px_14px_10px_0px_rgba(0,0,0,0.10)] shrink-0">
                <img src={CubeIcon} />
              </div>
              <span className="text-white-400 text-[1.6rem] font-500 leading-[2.24rem] max-w-[24rem]">
                {' '}
                <Trans
                  i18nKey="landing.journey.phase1.features.desc2"
                  defaults="<white>Smart contract transactions ensure</white> security and prevent early trading."
                  components={{ white: <span className="text-white" /> }}
                />
              </span>
            </div>
            <div className="flex items-center space-x-[2rem] rounded-[2rem] w-[36.5rem] h-[10.6rem] p-[2rem] journeyList-bg z-[1] lg:w-full">
              <div className="flex flex-col p-[1.3rem] w-[5.8rem] h-[5.8rem] bg-[#985AFF] rounded-[1.6rem] border-solid border-[0.1rem] border-white-300 shadow-[0px_14px_10px_0px_rgba(0,0,0,0.10)] shrink-0">
                <img src={ActivationIcon} />
              </div>
              <span className="text-white-400 text-[1.6rem] font-500 leading-[2.24rem] max-w-[24rem]">
                <Trans
                  i18nKey="landing.journey.phase1.features.desc3"
                  defaults="<white>A referral program rewards participants</white> for bringing in new members."
                  components={{ white: <span className="text-white" /> }}
                />
              </span>
            </div>
          </div>
          <img
            className="absolute top-[-9rem] right-0 h-[48rem] z-[0] lg:h-[12rem] lg:top-[10.6rem]"
            src="/images/landing/journey/1.webp"
          />
        </>
      ),
    },
    {
      content: (
        <>
          <div className="flex flex-col space-y-[1.2rem] ">
            <span className="text-[1.6rem] text-[#985AFF] font-600 space-grotesk lg:text-[1.4rem]">
              <Trans i18nKey="landing.journey.title">Polynetica's journey</Trans>
            </span>
            <div className="flex items-center space-x-[1.2rem] lg:justify-between lg:items-start">
              <span className="text-[4rem] font-600 leading-[4.4rem] space-grotesk text-white-500 lg:text-[2.4rem] lg:leading-[2.64rem] ">
                <span className="text-white">
                  <Trans i18nKey="landing.journey.phase.title">Phase</Trans> II
                </span>{' '}
                <Trans i18nKey="landing.journey.phase2.publicSale">Public Sale</Trans>
              </span>
              {currentProjectStatus === PROJECT_STATUS.PUBLIC_SALE && (
                <div className="flex items-center space-x-[1rem] bg-[rgba(206,250,47,0.10)] rounded-[0.8rem] px-[0.8rem] h-[3rem] lg:shrink-0">
                  <img src={ProgressIcon} />
                  <span className="text-[1.6rem] text-[#CEFA2F] font-500">
                    <Trans i18nKey="landing.journey.phase.status.inProgress">In progress</Trans>
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="flex space-x-[1.2rem] w-full lg:flex-col lg:space-x-0 lg:space-y-[1.2rem]">
            <div className="flex items-center space-x-[2rem] rounded-[2rem] w-[36.5rem] h-[10.6rem] p-[2rem] journeyList-bg z-[1] lg:w-full">
              <div className="flex flex-col p-[1.3rem] w-[5.8rem] h-[5.8rem] bg-[#985AFF] rounded-[1.6rem] border-solid border-[0.1rem] border-white-300 shadow-[0px_14px_10px_0px_rgba(0,0,0,0.10)] shrink-0">
                <img src={SoldIcon} />
              </div>
              <span className="text-white-400 text-[1.6rem] font-500 leading-[2.24rem] max-w-[24rem]">
                <Trans
                  i18nKey="landing.journey.phase2.features.desc1"
                  defaults="<white>Tokens are sold at a dynamic price</white>, increasing with each sale."
                  components={{ white: <span className="text-white" /> }}
                />
              </span>
            </div>
            <div className="flex items-center space-x-[2rem] rounded-[2rem] w-[36.5rem] h-[10.6rem] p-[2rem] journeyList-bg z-[1] lg:w-full">
              <div className="flex flex-col p-[1.3rem] w-[5.8rem] h-[5.8rem] bg-[#985AFF] rounded-[1.6rem] border-solid border-[0.1rem] border-white-300 shadow-[0px_14px_10px_0px_rgba(0,0,0,0.10)] shrink-0">
                <img src={LinkIcon} />
              </div>
              <span className="text-white-400 text-[1.6rem] font-500 leading-[2.24rem] max-w-[24rem]">
                {' '}
                <Trans
                  i18nKey="landing.journey.phase2.features.desc2"
                  defaults="<white>A robust referral program continues</white>, rewarding active participants."
                  components={{ white: <span className="text-white" /> }}
                />
              </span>
            </div>
            <div className="flex items-center space-x-[2rem] rounded-[2rem] w-[36.5rem] h-[10.6rem] p-[2rem] journeyList-bg z-[1] lg:w-full">
              <div className="flex flex-col items-center justify-center p-[1.3rem] w-[5.8rem] h-[5.8rem] bg-[#985AFF] rounded-[1.6rem] border-solid border-[0.1rem] border-white-300 shadow-[0px_14px_10px_0px_rgba(0,0,0,0.10)] shrink-0">
                <img src={PidIcon} />
              </div>
              <span className="text-white-400 text-[1.6rem] font-500 leading-[2.24rem] max-w-[24rem]">
                <Trans
                  i18nKey="landing.journey.phase2.features.desc3"
                  defaults="<white>The first affiliate structure (PID) is established</white>, laying the foundation for future programs."
                  components={{ white: <span className="text-white" /> }}
                />
              </span>
            </div>
          </div>
          <img
            className="absolute top-[-9rem] right-0 h-[48rem] z-[0] lg:h-[15rem] lg:top-[5.6rem]"
            src="/images/landing/journey/2.webp"
          />
        </>
      ),
    },
    {
      content: (
        <>
          <div className="flex flex-col space-y-[1.2rem] ">
            <span className="text-[1.6rem] text-[#985AFF] font-600 space-grotesk lg:text-[1.4rem]">
              <Trans i18nKey="landing.journey.title">Polynetica's journey</Trans>
            </span>
            <div className="flex items-center space-x-[1.2rem] lg:justify-between lg:items-start">
              <span className="flex flex-col text-[4rem] font-600 leading-[4.4rem] space-grotesk text-white-500 lg:text-[2.4rem] lg:leading-[2.64rem] ">
                <span className="text-white">
                  <Trans i18nKey="landing.journey.phase.title">Phase</Trans> III
                </span>{' '}
                <span>CEX/DEX Listing & Marketing Programs</span>
              </span>
              {currentProjectStatus === PROJECT_STATUS.MATRIX && (
                <div className="flex items-center space-x-[1rem] bg-[rgba(206,250,47,0.10)] rounded-[0.8rem] px-[0.8rem] h-[3rem] lg:shrink-0">
                  <img src={ProgressIcon} />
                  <span className="text-[1.6rem] text-[#CEFA2F] font-500">
                    <Trans i18nKey="landing.journey.phase.status.inProgress">In progress</Trans>
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="flex space-x-[1.2rem] w-full lg:flex-col lg:space-x-0 lg:space-y-[1.2rem]">
            <div className="flex items-center space-x-[2rem] rounded-[2rem] w-[36.5rem] h-[10.6rem] p-[2rem] journeyList-bg z-[1] lg:w-full">
              <div className="flex flex-col p-[1.3rem] w-[5.8rem] h-[5.8rem] bg-[#985AFF] rounded-[1.6rem] border-solid border-[0.1rem] border-white-300 shadow-[0px_14px_10px_0px_rgba(0,0,0,0.10)] shrink-0">
                <img src={ExchangeIcon} />
              </div>
              <span className="text-white-400 text-[1.6rem] font-500 leading-[2.24rem] max-w-[24rem]">
                <Trans
                  i18nKey="landing.journey.phase3.features.desc1"
                  defaults="<white>Tokens are listed on a centralized exchange</white>, opening up trading to a wider audience."
                  components={{ white: <span className="text-white" /> }}
                />
              </span>
            </div>
            <div className="flex items-center space-x-[2rem] rounded-[2rem] w-[36.5rem] h-[10.6rem] p-[2rem] journeyList-bg z-[1] lg:w-full">
              <div className="flex flex-col p-[1.3rem] w-[5.8rem] h-[5.8rem] bg-[#985AFF] rounded-[1.6rem] border-solid border-[0.1rem] border-white-300 shadow-[0px_14px_10px_0px_rgba(0,0,0,0.10)] shrink-0">
                <img src={BlockIcon} />
              </div>
              <span className="text-white-400 text-[1.6rem] font-500 leading-[2.24rem] max-w-[24rem]">
                {' '}
                <Trans
                  i18nKey="landing.journey.phase3.features.desc2"
                  defaults="<white>All restrictions on token sales are lifted</white>, enabling free transfer and trading."
                  components={{ white: <span className="text-white" /> }}
                />
              </span>
            </div>
            <div className="flex items-center space-x-[2rem] rounded-[2rem] w-[36.5rem] h-[10.6rem] p-[2rem] journeyList-bg z-[1] lg:w-full">
              <div className="flex flex-col items-center justify-center p-[1.3rem] w-[5.8rem] h-[5.8rem] bg-[#985AFF] rounded-[1.6rem] border-solid border-[0.1rem] border-white-300 shadow-[0px_14px_10px_0px_rgba(0,0,0,0.10)] shrink-0">
                <img src={MarketingIcon} />
              </div>
              <span className="text-white-400 text-[1.6rem] font-500 leading-[2.24rem] max-w-[24rem]">
                <Trans
                  i18nKey="landing.journey.phase3.features.desc3"
                  defaults="<white>Marketing programs are launched</white>, offering an opportunity to earn rewards."
                  components={{ white: <span className="text-white" /> }}
                />
              </span>
            </div>
          </div>
          <img
            className="absolute bottom-0 right-0 h-[48rem] z-[0] lg:h-[14.7rem] lg:top-[14rem] lg:right-[-1rem]"
            src="/images/landing/journey/3.webp"
          />
        </>
      ),
    },
  ];
  return (
    <div className="flex flex-col space-y-[8rem] items-center mt-[20rem] lg:space-y-[4rem] lg:mt-[8rem]">
      <Title
        title={<Trans i18nKey="landing.journey.title">Polynetica's journey</Trans>}
        className="text-[6rem] leading-[6.6rem] text-white "
      />
      <Slider slides={slides}>
        {slides.map((item, itemIndex) => (
          <div
            className="flex flex-col items-start bg-[#0C0C0E] justify-between p-[4rem] min-w-full justify-start relative overflow-hidden h-[50rem] rounded-[3rem] border border-solid border-white-100 shadow-[0px_0px_35px_0px_rgba(255,255,255,0.10)_inset] lg:h-[60rem] lg:px-[2rem] lg:items-start lg:pt-[4rem] lg:pb-[2rem] "
            key={itemIndex}
          >
            {item?.content}
          </div>
        ))}
      </Slider>
    </div>
  );
};
