import React from 'react';
import PolIcon from '../../../../assets/icons/main/polCoin.svg';

export const Profit = () => {
  const styleBg = {
    backgroundImage: `url('/images/dashboard/profile/profit/bg1.webp')`,
    backgroundSize: 'cover',
  };
  return (
    <div style={styleBg} className="relative flex items-center h-full overflow-hidden rounded-[2rem]">
      <div className="flex items-center w-full h-full p-[4rem] dashboard-block-styles lg:p-[2rem] lg:!bg-white-30">
        <div className="flex flex-col space-y-[1.2rem]">
          <span className="text-[1.6rem] text-white-500 font-500">Total profits</span>
          <div className="flex items-center space-x-[1.6rem]">
            <span className="text-[4.8rem] font-700 text-white lg:text-[3.2rem]">426 725.3</span>
            <img className="w-[4rem] h-[4rem] lg:w-[3.2rem] lg:h-[3.2rem]" src={PolIcon} />
          </div>
        </div>
      </div>
      <img
        className="absolute right-0 bottom-0 w-[31rem] lg:w-[18rem]"
        src={`/images/dashboard/profile/profit/pollyPlanet.webp`}
      />
    </div>
  );
};
