import React from 'react';
import { DefaultLayout } from '../../layouts';
import { HeaderBanner } from '../../features/login/HeaderBanner';
import { PreviewAccount } from '../../features/login/PreviewAccount';

export const Login = () => {
  return (
    <DefaultLayout>
      <HeaderBanner />
      <PreviewAccount />
    </DefaultLayout>
  );
};
