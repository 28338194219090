import React from 'react';
import PollyIcon from '../../../../assets/icons/generalIcons/whitePollyIcon.svg';
import loadingIcon from '../../../../assets/icons/register/terms/loading.svg';
import { Input } from '../../../../components';

export const RegisterBar = () => {
  return (
    <div className="flex space-x-[4rem] ml-[4rem] lg:flex-col lg:ml-0 lg:space-x-0 lg:space-y-[2rem] lg:mt-[2rem] register-plan-animation">
      <div className="border-[0.1rem] border-solid border-white-100" />
      <div className="flex flex-col justify-between space-y-[4.8rem] w-full custom-transition z-[1]">
        <div className="flex flex-col space-y-[2.4rem]">
          <div className="flex items-center justify-between w-full ">
            <img src={PollyIcon} />
            <div className="flex items-center space-x-[1.2rem] shrink-0 pr-[2.4rem] last:pr-0 ">
              <div className="w-[2.4rem] h-[2.4rem] loading-animation__purple" />
              <span className={`text-[1.4rem] lg:w-full ${'register-text-aniamtion'} lg:whitespace-nowrap`}>
                Sign in check
              </span>
            </div>
          </div>
          <div className="flex flex-col space-y-[2.4rem] ">
            <span className="text-[4rem] text-white font-600 space-grotesk tracking-[-0.8px] lg:text-[2.4rem] lg:tracking-[-0.48px]">
              Sign in to Polynetica
            </span>
            <span className="text-[1.4rem] text-white-500 font-500 leading-[2.24rem] max-w-[33rem]">
              Wallet 0xdd7s...6dad is not registered in Forsage. Create an account to join.{' '}
            </span>
          </div>
        </div>
        <div className="flex flex-col w-full space-y-[1.2rem]">
          <Input placeholder="Your upline" />
          <button className="flex items-center justify-center w-full h-[4.8rem] rounded-[1.2rem] bg-[#985AFF] text-white text-[1.6rem] font-400 tracking-[-0.48px] font-500 custom-transition hover:opacity-70">
            Sign in
          </button>
        </div>
      </div>
    </div>
  );
};
