import React from 'react';
import { Header } from '../../features/landing/Header';
import { Footer } from '../../features/landing/Footer';

export const StakingLayout = ({ children }) => {
  return (
    <div className="relative flex flex-col bg-[#0C0C0E] items-center justify-start min-h-screen min-w-full overflow-hidden lg:bg-[#020204] lg:px-[1.6rem]">
      <Header className="!bg-[#0C0C0E] !border-[#161616]" withMenu={false} withSteps={false} purpleLogo={true} />
      <div className="flex flex-col space-y-[4.8rem] z-[11] h-full text-white max-w-[128rem] w-full pt-[8rem] lg:pt-[12rem] px-[2rem] lg:px-0 pb-[2rem]">
        {children}
      </div>
      <Footer />
      <img className="absolute left-[20rem] lg:hidden z-[0]" src="/images/layouts/staking/stakingForm/shadow.webp" />
      <img
        className="hidden lg:flex absolute top-0 left-0 w-full z-[0]"
        src="/images/layouts/staking/stakingForm/mobShadow.webp"
      />
    </div>
  );
};
