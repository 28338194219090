import React from 'react';
import polygonIcon from '../../../../assets/icons/header/polygonIcon.svg';

export const Header = () => {
  return (
    <div className="flex w-full items-center justify-between">
      <span className="text-[2.4rem] text-white font-medium leading-normal lg:text-[1.8rem] ">Lvl 3</span>
      <div className="flex items-center justify-center px-[1rem] bg-[rgba(175,127,255,0.10)] rounded-full h-[3.7rem] lg:h-[3.1rem]">
        <span className="text-[2.4rem] text-[#AF7FFF] font-400 leading-none lg:text-[1.6rem]">ID 124059</span>
      </div>
      <div className="flex space-x-[1.2rem] items-center lg:space-x-0">
        <span className="text-[1.4rem] text-white-500 lg:hidden">Level Price</span>
        <div className="flex items-center space-x-[0.4rem]">
          <span className="text-[2.4rem] text-white lg:text-[1.8rem] font-600 ">5</span>
          <img className="lg:w-[2.4rem] lg:h-[2.4rem]" src={polygonIcon} />
        </div>
      </div>
    </div>
  );
};
