import { createSlice } from '@reduxjs/toolkit';

export const walletSlice = createSlice({
  name: 'wallet',
  initialState: {
    balance: {
      pol: 0,
      polly: 0,
      stPolly: 0,
    },
  },
  reducers: {
    updateBalance(state, action) {
      state.balance = { ...state.balance, ...action.payload };
    },
  },
});

export const walletReducer = walletSlice.reducer;

export const { updateBalance } = walletSlice.actions;
