import React, { useEffect, useMemo } from 'react';
import { ConnectWallet } from './States/ConnectWallet';
import { Register } from './States/Register';
import { Login } from './States/Login';
import { useWeb3React } from '@web3-react/core';
import { useGetUserInfo } from '../../../helpers/hooks/useGetUserInfo';

export const HeaderBanner = () => {
  const { account } = useWeb3React();
  const { userInfo, getUserInfo } = useGetUserInfo();

  useEffect(() => {
    if (account) {
      getUserInfo(account);
    }
  }, [account]);

  let connectWallet;
  let login;
  let register;

  const renderContent = useMemo(() => {
    if (!account) {
      return <ConnectWallet />;
    } else {
      if (userInfo?.user?.id) {
        return <Login />;
      }
      return <Register />;
    }
  }, [account, userInfo]);

  return (
    <div
      className={`
        ${(!account && 'connect-wallet-bg') || (!userInfo?.user?.id && 'register-bg')} 
        relative flex rounded-[3rem] h-[26rem] w-full bg-white-50 border-[0.1rem] border-white-50 p-[4rem] lg:h-[61.4rem] lg:max-w-full backdrop-blur-[5rem] bg-blend-difference lg:px-[2rem] lg:overflow-hidden`}
    >
      {renderContent}
      <img
        className="absolute top-0 left-0 rounded-t-[3rem] z-[0] h-full w-full lg:hidden"
        src="/images/login/headerBanner/topShadow.webp"
      />
      <img
        className="hidden lg:flex absolute top-0 left-0 rounded-t-[3rem] z-[0] h-full w-full "
        src="/images/login/headerBanner/topShadowMob.webp"
      />
    </div>
  );
};
