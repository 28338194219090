import React from 'react';
import { Header } from './Header';
import { LevelMap } from './LevelMap';
import { Footer } from './Footer';
import arrowIcon from '../../../assets/icons/level/levelCard/arrowIcon.svg';

export const LevelCard = () => {
  return (
    <div className="lg:flex lg:flex-col lg:space-y-[1.2rem]">
      <div className="flex items-center justify-center w-full">
        <div className="flex flex-col justify-between items-center py-[3rem] shrink-0  h-[31.7rem] bg-white-50 w-[10rem] backdrop-blur-[37.5px] bg-blend-difference border-l-[0.1rem] border-y-[0.1rem] border-white-50 rounded-l-[2.25rem] overflow-hidden lg:hidden">
          <button className="flex items-center justify-center !w-[4rem] !h-[4rem] bg-white-50 rounded-[1.2rem]">
            <img className="" src={arrowIcon} />
          </button>
          <span className="text-[1.4rem]  text-white-500 font-medium whitespace-nowrap">Level 2</span>
        </div>
        <div className="flex flex-col items-center space-y-[6rem] relative p-[4rem] h-[42.3rem] w-full bgLevelCard rounded-[3rem] border-[0.1rem] border-white-50 backdrop-blur-[5rem] overflow-hidden bg-blend-difference shadow-[0_40px_40px_10px_rgba(0,0,0,0.25)] lg:p-[2rem] lg:h-[40.3rem]">
          <Header />
          <LevelMap />
          <Footer />
          <img className="absolute bottom-0 w-full z-[-1] h-full" src="/images/level/shadow.png" />
        </div>
        <div className="flex flex-col justify-between items-center py-[3rem] shrink-0  h-[31.7rem] bg-white-50 !w-[10rem] backdrop-blur-[37.5px] bg-blend-difference border-r-[0.1rem] border-y-[0.1rem] border-white-50 rounded-r-[2.25rem] overflow-hidden lg:hidden">
          <button className="flex items-center justify-center !w-[4rem] !h-[4rem] bg-white-50 rounded-[1.2rem]">
            <img className="rotate-180" src={arrowIcon} />
          </button>
          <span className="text-[1.4rem]  text-white-500 font-medium whitespace-nowrap">Level 3</span>
        </div>
      </div>
      <div className="hidden lg:flex items-center justify-between p-[2rem] w-full h-[8rem] bg-white-50 rounded-[3rem] backdrop-blur-[3.75rem] border-[0.1rem] border-white-50 ">
        <button className="flex items-center justify-center !w-[4rem] !h-[4rem] bg-white-50 rounded-[1.2rem]">
          <img src={arrowIcon} />
        </button>
        <span className="text-[1.4rem] text-white-500 font-400">Levels</span>
        <button className="flex items-center justify-center !w-[4rem] !h-[4rem] bg-white-50 rounded-[1.2rem]">
          <img className="rotate-180" src={arrowIcon} />
        </button>
      </div>
    </div>
  );
};
