import dashboardIcon from '../assets/icons/menu/normal/dashboardIcon.svg';
import statisticsIcon from '../assets/icons/menu/normal/statisticsIcon.svg';
import documentsIcon from '../assets/icons/menu/normal/documentsIcon.svg';
import calculatorIcon from '../assets/icons/menu/normal/calculatorIcon.svg';
import tgIcon from '../assets/icons/menu/normal/tgIcon.svg';
import dashboardActiveIcon from '../assets/icons/menu/active/dashboardIcon.svg';
import statisticsActiveIcon from '../assets/icons/menu/active/statisticsIcon.svg';
import documentsActiveIcon from '../assets/icons/menu/active/documentsIcon.svg';
import calculatorActiveIcon from '../assets/icons/menu/active/calculatorIcon.svg';
import tgActiveIcon from '../assets/icons/menu/active/tgIcon.svg';

export const menuList = [
  {
    icon: dashboardIcon,
    activeIcon: dashboardActiveIcon,
    title: 'Dashboard',
    href: '/dashboard/program',
    key: '',
  },
  {
    icon: statisticsIcon,
    activeIcon: statisticsActiveIcon,
    title: 'Statistics',
    href: '/',
    key: '',
  },
  {
    icon: documentsIcon,
    activeIcon: documentsActiveIcon,
    title: 'Documents',
    href: '/',
    key: '',
  },
  {
    icon: calculatorIcon,
    activeIcon: calculatorActiveIcon,
    title: 'Calculator',
    href: '/',
    key: '',
  },
  {
    icon: tgIcon,
    activeIcon: tgActiveIcon,
    title: 'Telegram bots',
    href: '/',
    key: '',
  },
];
