import React from 'react';
import GreenArrow from '../../../../assets/icons/dashboard/greenArrow.svg';

export const Statistics = () => {
  const statisticsList = [
    {
      title: 'Total Profit',
      count: '1915',
    },
    {
      title: 'Total Revenue',
      count: '7000',
    },
    {
      title: 'Expenses',
      count: '1915',
    },
  ];
  return (
    <div className="flex items-center space-x-[2rem] w-full lg:rounded-[2rem] lg:border-[0.1rem] lg:border-solid lg:border-white-50 lg:bg-white-30 lg:backdrop-blur-[2.5rem] lg:px-[2rem] lg:h-[9.4rem] lg:space-x-0 lg:justify-between">
      {statisticsList.map((item, itemIndex) => {
        return (
          <div
            className="flex items-center justify-between w-full h-[7.2rem] rounded-[2rem] border-[0.1rem] border-solid border-white-50 bg-black-300 p-[2rem] lg:bg-transparent lg:border-none lg:p-0 lg:h-full lg:flex-col lg:items-start lg:space-y-[1.2rem] lg:items-start lg:justify-center lg:w-fit"
            key={itemIndex}
          >
            <span className="text-[1.4rem] text-white-500 font-500 lg:text-[1.2rem]">{item?.title}</span>
            <div className="flex items-center justify-center space-x-[1rem]">
              <span className="text-[2rem] font-600 text-white lg:text-[1.6rem]">{item?.count}</span>
              <div className="flex items-center justify-center space-x-[0.5rem]">
                <img src={GreenArrow} />
                <span className="text-[1.4rem] text-[#51F689] font-400 leading-normal">2</span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
