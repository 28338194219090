import React from 'react';
import { BaseLayout } from 'layouts';
import { Profile } from '../../features/dashboard/Profile';
import { Stats } from '../../features/dashboard/Stats';
import { Programs } from '../../features/dashboard/Programs';

export const Dashboard = () => {
  return (
    <BaseLayout className="lg:!bg-[#0C0C0E]">
      <div className="flex flex-col items-center space-y-[2rem]">
        <Profile />
      </div>
      <Programs />
    </BaseLayout>
  );
};
