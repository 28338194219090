import React, { useEffect, useState, useRef } from 'react';
import { StakingForm } from '../../features/staking/Form';
import { StakingList } from '../../features/staking/List';
import { StakingLayout } from '../../layouts/StakingLayout';
import { useWeb3React } from '@web3-react/core';
import { useStaking } from '../../helpers/hooks/useStaking';

export const StakingPage = () => {
  const { account } = useWeb3React();
  const { getUserDeposits, userDeposits } = useStaking();

  useEffect(() => {
    if (account) {
      getUserDeposits();
    }
  }, [account]);

  return (
    <StakingLayout>
      <div className="mt-[4.8rem] text-white text-[4.8rem] font-600 space-grotesk lg:text-[2.4rem] lg:mt-[1rem]">
        <span>
          <span className="opacity-40">Polynetica</span> Staking
        </span>
      </div>
      <StakingForm getUserDeposits={getUserDeposits} />
      <StakingList userDeposits={userDeposits} getUserDeposits={getUserDeposits} />
    </StakingLayout>
  );
};
