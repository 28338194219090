import React from 'react';
import { useSelector } from 'react-redux';
import { getTiersInfo } from '../../../../../../store/tierSlice/selectors';
import { ALL_TIERS_STATUS } from '../../../../../../helpers/tiers';

export const Menu = ({ onNavigate, onClose, isOpenedBurger, setIsOpenedBurger }) => {
  const { allStatus } = useSelector(getTiersInfo);

  const baseMenu = [
    {
      title: 'Marketing Programs',
      section: 'programs',
    },
    {
      title: "Polynetica's journey ",
      section: 'journey',
    },
    {
      title: 'Circular Economy',
      section: 'economy',
    },
  ];

  const menuList =
    allStatus === ALL_TIERS_STATUS.ENDED
      ? [
          {
            title: 'Public Sale',
            section: 'publicSale',
          },
          ...baseMenu,
        ]
      : [
          {
            title: 'Tiers',
            section: 'tiers',
          },
          ...baseMenu,
        ];

  const isMobile = window.innerWidth <= 992;

  return (
    <div className="flex flex-col space-y-[1.2rem] w-full">
      {menuList.map((item, itemIndex) => {
        return (
          <button
            className="flex"
            onClick={() => {
              onNavigate(item?.section);
              if (isMobile) {
                setIsOpenedBurger(!isOpenedBurger);
              } else {
                onClose();
              }
            }}
          >
            <span
              className="cursor-pointer custom-transition text-[2rem] font-600 text-white-500 leading-[2.8rem] hover:text-white"
              key={itemIndex}
            >
              {item?.title}
            </span>
          </button>
        );
      })}
    </div>
  );
};
