import config from './config';

export const TIERS_START_DATA = config.stand === 'prod' ? '1734004800' : '1733828400';

export const OPENSALE_END_DATA = config.stand === 'prod' ? '1737972000' : '1737930881';
export const DEX_START_DATA = config.stand === 'prod' ? '1737979200' : '1737931781';

export const TIERS_STATUS = {
  SOON: 'Soon',
  ACTIVE: 'Active',
  SOLDOUT: 'Sold out',
};

export const ALL_TIERS_STATUS = {
  SOON: 'Soon',
  SALE: 'Sale',
  ENDED: 'Ended',
};

export const TIER_END_TIME = [1734447600, 1734447600, 1734447600, 1734620400, 1734879600];

export const TIERS = [
  {
    index: 0,
    status: TIERS_STATUS.SOON,
    info: {
      maxTokens: 2400000000,
      tokensInSlot: 5000000,
      pricePerToken: 0.000035,
      slots: 480,
      price: 176,
      priceUsd: 125,
      refBonus: 500000,
      tokensSold: 0,
      slotsSold: 0,
    },
  },
  {
    index: 1,
    status: TIERS_STATUS.SOON,
    info: {
      maxTokens: 2400000000,
      tokensInSlot: 4000000,
      pricePerToken: 0.000042,
      slots: 600,
      price: 169,
      priceUsd: 120,
      refBonus: 400000,
      tokensSold: 0,
      slotsSold: 0,
    },
  },
  {
    index: 2,
    status: TIERS_STATUS.SOON,
    info: {
      maxTokens: 2400000000,
      tokensInSlot: 3000000,
      pricePerToken: 0.00005,
      slots: 800,
      price: 148,
      priceUsd: 105,
      refBonus: 300000,
      tokensSold: 0,
      slotsSold: 0,
    },
  },
  {
    index: 3,
    status: TIERS_STATUS.SOON,
    info: {
      maxTokens: 2400000000,
      tokensInSlot: 2000000,
      pricePerToken: 0.000064,
      slots: 1200,
      price: 127,
      priceUsd: 90,
      refBonus: 200000,
      tokensSold: 0,
      slotsSold: 0,
    },
  },
  {
    index: 4,
    status: TIERS_STATUS.SOON,
    info: {
      maxTokens: 2400000000,
      tokensInSlot: 1000000,
      pricePerToken: 0.0001,
      slots: 2400,
      price: 99,
      priceUsd: 70,
      refBonus: 100000,
      tokensSold: 0,
      slotsSold: 0,
    },
  },
];
