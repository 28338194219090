import React, { useState } from 'react';
import { RegisterLayout } from '../../layouts';
import { Plans } from '../../features/register/plans';

export const Register = () => {
  return (
    <RegisterLayout>
      <Plans />
    </RegisterLayout>
  );
};
