import React, { useState, useMemo, useEffect } from 'react';
import { StakingListItem } from './Item';
import { useStaking } from '../../../helpers/hooks/useStaking';
import { useCallTransaction } from '../../../helpers/hooks/useCallTransaction';
import { callNotification } from '../../../helpers/notification';

export const StakingList = ({ userDeposits = [], getUserDeposits }) => {
  if (!userDeposits.length) {
    return null;
  }

  const [disabledAllUnstake, setDisabledAllUnstake] = useState(false);
  const { claim, unstake } = useStaking();
  const {
    onCallTransaction: onCallTransactionUnstake,
    transactionInfo: transactionInfoUnstake,
    resetTransactionInfo: resetTransactionInfoUnstake,
  } = useCallTransaction();
  const {
    onCallTransaction: onCallTransactionClaim,
    transactionInfo: transactionInfoClaim,
    resetTransactionInfo: resetTransactionInfoClaim,
  } = useCallTransaction();

  const onClickClaim = async (depositIndex) => {
    try {
      const result = await claim(depositIndex);

      if (result) {
        onCallTransactionClaim(result);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onClickUnstake = async (depositIndex) => {
    setDisabledAllUnstake(true);
    try {
      const result = await unstake(depositIndex);

      if (result) {
        onCallTransactionUnstake(result);
      }
    } catch (e) {
      console(e);
    } finally {
      setDisabledAllUnstake(false);
    }
  };

  useEffect(() => {
    if (transactionInfoUnstake.isSuccess) {
      callNotification({ type: 'success', message: 'Unstaked' });
      getUserDeposits();
    }
    if (transactionInfoUnstake.isWaiting) {
      callNotification({ type: 'info', message: 'Waiting to Unstake' });
    }
  }, [transactionInfoUnstake]);

  useEffect(() => {
    if (transactionInfoClaim.isSuccess) {
      callNotification({ type: 'success', message: 'Claimed reward' });
    }
    if (transactionInfoClaim.isWaiting) {
      callNotification({ type: 'info', message: 'Waiting to claim' });
    }
  }, [transactionInfoClaim]);

  const renderList = useMemo(() => {
    return (
      <div className="grid grid-cols-3 w-full gap-[2rem] lg:grid-cols-1">
        {userDeposits.map((item, itemIndex) => {
          return (
            <StakingListItem
              {...item}
              index={itemIndex}
              key={'stake' + itemIndex}
              disabledAllUnstake={disabledAllUnstake}
              onClickClaim={() => onClickClaim(itemIndex)}
              onClickUnstake={() => onClickUnstake(itemIndex)}
            />
          );
        })}
      </div>
    );
  }, [userDeposits, disabledAllUnstake]);

  return (
    <div className="flex flex-col space-y-[4rem]">
      <div className="flex items-center justify-start">
        <span className="text-[3.2rem] text-white font-600 lg:text-[2.4rem]">Your Stakes</span>
      </div>
      {renderList}
    </div>
  );
};
