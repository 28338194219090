import React from 'react';
import { Reflink } from './Reflink';
import { UserInfo } from './UserInfo';
import { MiniPrograms } from './MiniPrograms';
import { Profit } from './Profit';
import { Statistics } from './Statistics';

export const Profile = () => {
  return (
    <div className="flex flex-col space-y-[2rem] w-full rounded-[3rem] border-[0.1rem] border-solid border-white-50 backdrop-blur-[1.5rem] dashboard-profile-bg p-[2rem] lg:border-transparent lg:rounded-none lg:p-0 lg:space-y-0">
      <div className="flex items-start justify-center space-x-[2rem] w-full h-full lg:flex-col lg:space-x-0 lg:space-y-[2.4rem] lg:px-[1.6rem]">
        <UserInfo />
        <div className="flex flex-col space-y-[2rem] w-full h-full lg:flex-col lg:space-y-[1.2rem] ">
          <div className="flex space-x-[2rem] w-full lg:flex-col lg:space-x-0">
            <Reflink className="lg:hidden" />
            <MiniPrograms />
          </div>
          <Profit />
        </div>
      </div>
      <div className="flex flex-col space-y-[1.2rem] lg:px-[1.6rem] lg:pt-[1.2rem]">
        <Statistics />
        <Reflink className="hidden lg:flex" />
      </div>
      <img
        className="hidden lg:flex absolute top-0 left-0 z-[0] h-[35rem] w-full"
        src="/images/dashboard/topShadowMob.webp"
      />
    </div>
  );
};
