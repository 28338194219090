import React, { useCallback } from 'react';
import Countdown from 'react-countdown';
import { fromUnixTime } from 'date-fns';

export const TierItemTimer = ({ time, onComplete, isCompleted }) => {
  const timerItem = (num) => {
    return <span className="text-[1.4rem] font-500 leading-[140%]">{num}</span>;
  };

  const renderer = useCallback(({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return null;
    } else {
      return (
        <div className="flex items-center justify-center">
          {timerItem(`${days < 0 ? hours : hours + days * 24}`)}:
          {timerItem(`${minutes < 10 ? '0' + minutes : minutes}`)}:
          {timerItem(`${seconds < 10 ? '0' + seconds : seconds}`)}
        </div>
      );
    }
  }, []);

  return <Countdown renderer={renderer} autoStart date={fromUnixTime(time)} onComplete={onComplete} overtime />;
};
