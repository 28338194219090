import React from 'react';
import { MobHeader } from '../../components/MobHeader';
import { Footer } from '../../features/landing/Footer';

export const RegisterLayout = ({ children }) => {
  return (
    <div className="relative flex flex-col bg-[#020204] items-center justify-start min-h-screen min-w-full overflow-hidden lg:bg-[#020204] lg:px-[1.6rem]">
      <MobHeader className="!bg-[rgba(2,2,4,0.80)] !backdrop-blur-[1.5rem]" />
      <img
        className="absolute w-[15.3rem] m-[0.8rem] z-[1] left-[6rem] top-[3rem] lg:hidden"
        src="/images/layouts/logo.webp"
      />
      <div className="flex items-center justify-center w-full max-w-[134rem] mt-[14rem] z-[1] lg:justify-start lg:items-start lg:mt-[10.4rem]">
        {children}
      </div>
      <Footer className="!hidden lg:!flex " />
      <img className="absolute left-0 h-full lg:hidden z-[0]" src="/images/layouts/register/shadow.webp" />
      <img className="absolute right-0 lg:hidden z-[0]" src="/images/layouts/register/rightShadow.webp" />
      <img className="absolute top-0 w-full hidden lg:flex z-[0]" src="/images/layouts/register/mobShadow.webp" />
      <img
        className="absolute bottom-0 w-full hidden lg:flex z-[0]"
        src="/images/layouts/register/mobBottomShadow.webp"
      />
    </div>
  );
};
