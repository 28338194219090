import React, { useState } from 'react';
import { AnimatedCounter } from '../../../components';

export const StakingDays = ({ period, setPeriod, periodVariants, bonusAPR }) => {
  return (
    <div className="flex flex-col space-y-[1.6rem] w-full">
      <div className="flex w-full justify-between">
        <span className="text-[1.4rem] font-500 leading-[1.6rem] text-white-400">
          Stake, <span className="text-white">Days</span>
        </span>
        {!!bonusAPR && (
          <div className="flex items-center space-x-[0.4rem] text-[1.4rem] text-white leading-[1.6rem] font-500">
            <span className="text-white-400">Bonus APY</span>
            <AnimatedCounter endNum={bonusAPR} />
            <span>%</span>
          </div>
        )}
      </div>
      <div className="flex items-center space-x-[0.8rem]">
        {periodVariants.map((item, itemIndex) => {
          const isActive = period === item;
          return (
            <button
              onClick={() => setPeriod(item)}
              className={`flex items-center justify-center w-full h-[4rem] rounded-[1.2rem] border-[0.1rem] border-solid custom-transition ${
                isActive
                  ? 'border-white-100 bg-white-200 text-white'
                  : 'border-transparent  bg-white-50  text-white-300'
              }`}
              key={itemIndex}
            >
              <span className="text-[1.6rem] font-500 leading-[2rem]">{item / 86400}</span>
            </button>
          );
        })}
      </div>
    </div>
  );
};
