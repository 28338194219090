import React from 'react';
import mobPolyicon from '../../assets/icons/header/mobPolyicon.svg';

export const MobHeader = ({ className }) => {
  return (
    <div
      className={`hidden lg:flex absolute top-0 px-[1.6rem] items-center justify-between bg-[linear-gradient(0deg,rgba(255,255,255,0.04)_0%,rgba(255,255,255,0.04)_100%),#020204] z-[11] backdrop-blur-[5rem] h-[6.4rem] w-full ${className}`}
    >
      <img src={mobPolyicon} />
    </div>
  );
};
