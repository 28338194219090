import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getBalance } from '../../../store/walletSlice/selectors';

export const StakingInput = ({ inputValue, setInputValue }) => {
  const { polly: balancePolly } = useSelector(getBalance);

  const changeInput = (value) => {
    if (value > balancePolly) {
      setInputValue(balancePolly);
    } else {
      if (value < 0) {
        setInputValue(Math.abs(value));
      } else {
        setInputValue(value);
      }
    }
  }

  return (
    <div className="flex flex-col space-y-[1.6rem] w-full">
      <span className="text-[1.4rem] font-500 leading-[1.6rem] w-full">Amount</span>
      <div className="flex items-center w-full justify-between h-[5.6rem] bg-white-10 border-[0.1rem] border-white-100 w-full rounded-[1.6rem] pr-[0.8rem]">
        <input
          value={inputValue}
          type='number'
          onChange={(e) => changeInput(e.target.value)}
          className="pl-[2.4rem] w-full h-full bg-transparent text-[1.4rem] leading-[2rem] text-white placeholder:text-white-300 outline-none placeholder:text-white-300 lg:text-[1.2rem]"
          placeholder="Enter the amount"
        />
        <div className="flex items-center space-x-[1.2rem]">
          <span className="text-[1.4rem] leading-[2rem] text-white-300 lg:text-[1.2rem]">$POLLY</span>
          <div className=" bg-white-100 w-[0.1rem] h-[2rem]" />
          <button onClick={() => setInputValue(balancePolly)} className="bg-[#AF7FFF] rounded-[1.2rem] px-[1.2rem] h-[4rem]">
            <span className="text-white font-500 leading-[2rem] lg:text-[1.2rem]">Max</span>
          </button>
        </div>
      </div>
    </div>
  );
};
