import { useState } from 'react';
import { ContractNames } from '../constants';
import { useWeb3React } from '@web3-react/core';
import { useGetContract } from './useGetContract';
import { toWei } from '../numbers';
import { bigIntToInt } from '../numbers';

export const useStaking = () => {
  const { account } = useWeb3React();
  const { getContract } = useGetContract();
  const [userDeposits, setUserDeposits] = useState([]);
  const [basicAPR, setBasicAPR] = useState(null);
  const [amountAPR, setAmountAPR] = useState(null);

  const stake = async (amount = 0, period) => {
    try {
      const value = Number(amount) ? toWei(amount) : 0;
      const contractStaking = await getContract(ContractNames.STAKING);
      const result = await contractStaking.stake(value, period);

      if (result) {
        console.log(result);
        return result;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const unstake = async (index = 0) => {
    try {
      const contractStaking = await getContract(ContractNames.STAKING);
      const result = await contractStaking.unstake(index);

      if (result) {
        return result;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const claim = async (index = 0) => {
    try {
      const contractStaking = await getContract(ContractNames.STAKING);
      const result = await contractStaking.claimByIndex(index);

      if (result) {
        return result;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getUserDeposits = async () => {
    try {
      const contractStaking = await getContract(ContractNames.STAKING);
      const result = await contractStaking.getUserDeposits(account);

      if (result) {
        setUserDeposits(result.deposits);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getBaseApr = async () => {
    try {
      const contractStaking = await getContract(ContractNames.STAKING);
      const result = await contractStaking.getAmountPeriodApr(toWei(100000), 604800);

      if (result) {
        setBasicAPR((bigIntToInt(result) * 100).toFixed(1));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getAmountPeriodApr = async (amount, period) => {
    if (!!period) {
      try {
        const contractStaking = await getContract(ContractNames.STAKING);
        const result = await contractStaking.getAmountPeriodApr(toWei(amount), period);

        if (result) {
          setAmountAPR((bigIntToInt(result) * 100).toFixed(1));
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  return {
    stake,
    getUserDeposits,
    userDeposits,
    getBaseApr,
    basicAPR,
    getAmountPeriodApr,
    amountAPR,
    unstake,
    claim,
  };
};
