import React from 'react';
import telegramIcon from '../../assets/icons/socials/telegramIcon.svg';
import youtubeIcon from '../../assets/icons/socials/youtubeIcon.svg';
import twitterIcon from '../../assets/icons/socials/twitterIcon.svg';
import { socialLinks } from '../../helpers/socialLinks';
import { Link } from 'react-router-dom';

export const SocialLinksComp = ({ className }) => {
  return (
    <div className={`flex space-x-[1.375rem] ${className}`}>
      <Link className="custom-transition hover:opacity-80" target="blank" to={socialLinks.telegramChannel}>
        <img src={telegramIcon} />
      </Link>
      <Link className="custom-transition hover:opacity-80" target="blank" to={socialLinks.youtube}>
        <img src={youtubeIcon} />
      </Link>
      <Link className="custom-transition hover:opacity-80" target="blank" to={socialLinks.twitter}>
        <img src={twitterIcon} />
      </Link>
    </div>
  );
};
