import React from 'react';
import { Link } from 'react-router-dom';

export const DefaultLayout = ({ children, style }) => {
  return (
    <div
      style={style}
      className="overflow-x-hidden relative min-h-screen w-screen flex flex-col items-center justify-start lg:justify-start pt-[11rem] lg:pt-[8rem] lg:px-[1.6rem]"
    >
      <header className="lg:backdrop-blur-md max-w-[144rem] z-[10] fixed top-0 left-1/2 -translate-x-1/2 w-full lg:bg-[rgba(2,2,4,0.80)] lg:max-w-full lg:py-[1rem] lg:px-[1.6rem] py-[3rem] pl-[6rem] pr-[4rem] flex items-center justify-between">
        <img className="h-[4rem] lg:h-[3rem]" src="/logo.svg" alt="" />
        <Link
          className="previewPage-header-btn rounded-[1.2rem] border border-solid border-[rgba(255,255,255,0.5)] bg-[#985AFF] hover:bg-[#B678F5] custom-transition flex items-center justify-center space-x-[1rem] py-[0.8rem] px-[1.6rem]"
          to="https://t.me/polynetica"
          target="_blank"
        >
          <span className="text-white text-[1.6rem] font-medium">Get info</span>
          <img className="h-[2.4rem] w-[2.4rem]" src="/icons/bookmark.svg" alt="" />
        </Link>
      </header>
      <div className="flex flex-col justify-start items-center space-y-[2rem] w-full max-w-[144rem]">
        {children}
      </div>
      <img
        className="absolute top-0 left-0 w-full h-auto lg:w-[100rem] lg:left-[-5rem] lg:h-[50rem] z-[-1] lg:hidden"
        src="/images/layouts/default/topLight-purple.webp"
        alt=""
      />
    </div>
  );
};
